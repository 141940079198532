import React from 'react'
import { useTranslation } from 'react-i18next'

import icons from '../img/icons/index'
const { IconAddItem } = icons

const AddButton = ({ onClick, textKey, disabled = false, id }) => {
  const { t } = useTranslation()

  return (
    <div
      className={`v2-button sm ${disabled ? 'disabled' : ''}`}
      id={id}
      onClick={disabled ? null : onClick}
    >
      <IconAddItem color='#ffffff' width='24px' />
      {t(textKey)}
    </div>
  )
}

export default AddButton
