import html2pdf from 'html2pdf.js'
import { prepareMenuData } from './prepareMenuData'
import { createPageContent } from './createPageContent'
import { createPreviewPageContent } from './createPreviewPageContent'
import { createItemContent } from './createItemContent'
import { getContentHeight } from './getContentHeight'
import { distributeContentAcrossPages } from './distributeContentAcrossPages'
import { distributeContentAcrossPagesCategory } from './distributeContentAcrossPagesCategory'
import { getMenu } from '../helpers'
export const generatePdfZip = async (
  token,
  setPdfWidth,
  user,
  id,
  usePDFMenu,
  width,
  height,
  isThreeColumn,
  filterCategory,
  fileSuffix,
  templateSize,
  useCategoryDistributor = true
) => {
  setPdfWidth(width)

  const currentMenu = await getMenu(token, user.sub, id)

  if (!currentMenu?.data?.content) {
    console.error('Content is undefined or missing in currentMenu')
    return
  }

  const allData = prepareMenuData(currentMenu)

  if (!allData) {
    console.error('[ERROR] Failed to prepare menu data.')
    return
  }

  let pdfsBlob = []

  for (const { data, menuName, lang, templateName, wifi } of allData) {
    const pages = useCategoryDistributor
      ? distributeContentAcrossPagesCategory(
          data,
          currentMenu,
          height,
          isThreeColumn ? 345 : 280,
          isThreeColumn,
          filterCategory,
          getContentHeight,
          createItemContent
        )
      : distributeContentAcrossPages(data, currentMenu)

    const previewHtmlContent = await createPreviewPageContent(
      currentMenu,
      templateName,
      templateSize
    )
    const htmlPages = pages
      .map((page, index) => createPageContent(page, index, templateSize))
      .join('')
    const htmlContent = `<div>${previewHtmlContent}${htmlPages}</div>`

    usePDFMenu.current.innerHTML = htmlContent

    const pdfBlob = await html2pdf()
      .from(usePDFMenu.current)
      .set({
        margin: 0,
        filename: `${menuName}-${lang}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          allowTaint: false,
        },
        jsPDF: { unit: 'in', format: templateSize.toLowerCase(), orientation: 'portrait' },
      })
      .outputPdf('blob')

    const fileName = menuName?.trim()
      ? `${menuName}-${templateSize.toLowerCase()}-${fileSuffix}-${lang}.pdf`
      : `menu-${templateSize.toLowerCase()}-${fileSuffix}-${lang}.pdf`

    pdfsBlob.push({
      name: fileName,
      blob: pdfBlob,
    })
  }

  return pdfsBlob
}
