export const prepareMenuData = (currentMenu) => {
  if (!currentMenu?.data?.content) {
    return null
  }

  const fallbackContent = structuredClone(currentMenu.data.content[0])
    .subItems?.filter((category) => category.isVisible)
    .map((category) =>
      category.subItems
        ?.filter((subCategory) => subCategory.isVisible)
        .map((subCategory) => subCategory.subItems?.filter((item) => item.isVisible))
    )

  const allData = currentMenu.data.content.map((content) => {
    const data = content.subItems
      ?.filter((category) => category.isVisible)
      ?.map((category, categoryIndex) => ({
        catName: category.catName || fallbackContent.subItems?.[categoryIndex]?.catName || '',
        isThreeColumnLayout: category.isThreeColumnLayout || false,
        subItems: category.subItems
          ?.filter((subCategory) => subCategory.isVisible)
          ?.map((subCategory, subCategoryIndex) => ({
            subcatName:
              subCategory.subcatName ||
              fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]?.subcatName ||
              '',
            subItems: subCategory.subItems
              ?.filter((item) => item.isVisible)
              ?.map((item, itemIndex) => ({
                itemName:
                  item.itemName ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.itemName ||
                  '',
                description:
                  item.description ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.description ||
                  '',
                price:
                  item.price ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.price ||
                  '',
                subVariant1:
                  item.subVariant1 ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.subVariant1 ||
                  '',
                subVariant2:
                  item.subVariant2 ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.subVariant2 ||
                  '',
                subVariant3:
                  item.subVariant3 ||
                  fallbackContent.subItems?.[categoryIndex]?.subItems?.[subCategoryIndex]
                    ?.subItems?.[itemIndex]?.subVariant3 ||
                  '',
                isListMode: item.isListMode || false,
              })),
          })),
      }))
      .flat()

    return {
      data,
      menuName: content.menuName || fallbackContent?.menuName,
      lang: content.lang,
      templateName: currentMenu.data.templateName,
      wifi: currentMenu.data.wifi,
    }
  })

  return allData
}
