import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { ntfWarn } from '../helpers/notifications'
import { validateItemMeasure } from '../validation/validate'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'

const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const SubVariantCardDebouncedInput = ({
  incomingName,
  incomingValue,
  incomingId,
  catIndex,
  subcatIndex,
  itemIndex,
  variantIndex,
  variantGroup,
}) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState(incomingValue)

  useEffect(() => {
    const targetItem = menuLocals.find((langObj) => langObj.lang === currentLanguage)?.subItems?.[
      catIndex
    ]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]?.[`subVariant${variantGroup}`]?.values?.[
      variantIndex
    ]

    setInputValue(targetItem?.[incomingName] || '')
  }, [incomingValue, menuLocals, currentLanguage])

  const handleInputChange = (name, value, incomingId) => {
    // console.log(`Updating field: ${name}, Value: ${value}, ID: ${incomingId}`)

    const _menuLocals = menuLocals.map((menu) => {
      if (menu.lang === currentLanguage) {
        const item =
          menu.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]?.[
            `subVariant${variantGroup}`
          ]?.values?.[variantIndex]

        if (!item) {
          // console.error(`Item with ID ${incomingId} not found in menuLocals`)
          return menu
        }

        item[name] = value
        if (name === 'subVariantName' && value.trim() !== '') {
          delete item.isNew
        }
      }
      return menu
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleInputChange = useDebouncedCallback(
    handleInputChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  return (
    <input
      className='form-control'
      name={incomingName}
      id={`subvariant-${catIndex}-${subcatIndex}-${itemIndex}-${variantIndex}-${incomingName}`}
      value={inputValue}
      // value={inputValue === '(placeholder)' ? '' : inputValue}
      disabled={isLocalsLoading}
      placeholder={incomingName === 'subVariantName' ? placeholders.subVariant : ''}
      onChange={(e) => {
        const name = e.target.name
        const value = e.target.value

        // console.log(`Debounced Input Change: ${name} = ${value}, ID: ${incomingId}`)

        let error = null
        if (incomingName === 'price' || incomingName === 'sku') {
          error = validateItemMeasure(value).error
        }

        if (!error) {
          setInputValue(value)
          debouncedHandleInputChange(name, value, incomingId)
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_10_DIGITS_MAX') })
        }
      }}
    />
  )
}

export default SubVariantCardDebouncedInput
