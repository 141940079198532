export const getContentHeight = (content) => {
  const tempDiv = document.createElement('div')
  tempDiv.style.position = 'absolute'
  tempDiv.style.visibility = 'hidden'
  tempDiv.style.width = '100%'
  tempDiv.style.top = '-9999px'
  tempDiv.innerHTML = content
  document.body.appendChild(tempDiv)
  const height = tempDiv.offsetHeight
  document.body.removeChild(tempDiv)
  return height
}
