import React, { useState, useContext, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import Skeleton from 'react-loading-skeleton'
// import qr from 'qr-image'
import qr from 'qrcode'
import JSZip from 'jszip'
import { getIndicator, getUser, deleteMenu, getMenu } from '../helpers'
import { RootGetterContext, RootSetterContext, ManagerSetterContext } from '../context/userContext'
import MenuLanguageBlock from './MenuLanguageBlock'
import { fullList as availableLanguages } from '../data/langList'
import { ntfErrorWithLink } from '../helpers/notifications'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'
import DefaultLogo from '../img/DefaultLogo'
import { generatePdfZip } from '../helpersPDF'
import { PDF_HEIGHT, PDF_WIDTH_A4, PDF_HEIGHT_A5, PDF_WIDTH_A5 } from '../helpersPDF/constants'

const { REACT_APP_CLOUDINARY_LOGO_PATH } = process.env

const zip = new JSZip()

const { IconCloneMenu, IconPen, IconRemoveItem } = icons

const TheIndicator = ({ events }) => {
  const { t, i18n } = useTranslation()
  const [itemIndicator, setItemIndicator] = useState(null)
  let timeout = null

  const autoUpdateIndicator = async () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setItemIndicator(getIndicator(events, i18n.language, t))
    timeout = setTimeout(async () => {
      autoUpdateIndicator()
    }, process.env.REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
  }

  useEffect(() => {
    if (events) {
      autoUpdateIndicator()
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [events, i18n.language])

  return (
    <div className='updated-at'>
      {itemIndicator ? (
        <>
          {itemIndicator?.map((item, i) => (
            <h4 key={i}>{item}</h4>
          ))}
        </>
      ) : (
        <h4>
          <Skeleton />
        </h4>
      )}
    </div>
  )
}

const MenuItem = ({ history, data }) => {
  const { token } = useContext(RootGetterContext)
  const { setShowRemoveModal, isLoadingGlobal, setIsLoadingGlobal } = useContext(RootSetterContext)
  const { setUserObject, setShowAliasOnlyModal } = useContext(ManagerSetterContext)
  const { user } = useAuth0()
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const usePDFMenu = useRef(null)
  const useMenuItem = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [pdfWidth, setPdfWidth] = useState(PDF_WIDTH_A4)

  const { id, logo, events, translations, alias, defaultLanguage } = data ?? {}
  const url = alias && `https://${alias}.gimme.menu`

  const handleRedirect = () => {
    history.push(`/my/${id}`)
  }

  const handleCloneMenu = async () => {
    if (isLoadingGlobal) return
    setShowAliasOnlyModal(id)
  }

  const handleDeleteMenu = async () => {
    if (isLoadingGlobal) return
    setIsLoadingGlobal(true)
    try {
      await deleteMenu(token, user.sub, id)
      const userData = await getUser(token, user.sub)
      if (userData.success) {
        setUserObject(userData.data)
      } else {
        ntfErrorWithLink({
          title: t('MESSAGE_ERROR_TITLE'),
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
      }
      return userData
    } catch (e) {
      console.error(`handleDeleteMenu error:\n${e.toString()}`)
      ntfErrorWithLink({
        title: t('MESSAGE_ERROR_TITLE'),
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleSaveQrZip = async () => {
    let filenamesArray = []
    for (let i = 0; i < translations.length; i++) {
      const urlToEncode =
        translations[i].lang !== defaultLanguage ? `${url}?lang=${translations[i].lang}` : url

      const qrcodePngString = await qr.toDataURL(urlToEncode, {
        type: 'utf8',
        scale: 10,
        margin: 1,
      })

      const pngBlob = dataURLtoBlob(qrcodePngString)

      const svgString = await qr.toString(urlToEncode, {
        type: 'svg',
      })
      const insertIndex = svgString.indexOf('><')
      const svgFilled = svgString
        .slice(0, insertIndex + 1)
        .concat(
          '<rect x="0" y="0" width="310" height="310" style="fill:#ffffff"/>',
          svgString.slice(insertIndex + 1)
        )

      const svgBlob = new Blob([svgFilled], {
        type: 'image/svg+xml;charset=utf-8',
      })

      let svgName
      availableLanguages.forEach((lang) => {
        if (translations[i].lang === lang.value) {
          svgName = translations[i].lang === defaultLanguage ? `${lang.name}(default)` : lang.name
        }
      })
      zip.file(`menu_${svgName}.svg`, svgBlob)
      zip.file(`menu_${svgName}.png`, pngBlob)
      filenamesArray.push(`menu_${svgName}.svg`, `menu_${svgName}.png`)
    }

    const zipResult = await zip.generateAsync({ type: 'blob' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipResult)
    a.setAttribute('download', 'menu-qr-codes.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    filenamesArray.forEach((item) => zip.remove(item))
  }

  const packMd = (locale, includeHidden = false, includeSku = false) => {
    let md = ``

    for (const category of locale.subItems) {
      if (!includeHidden && !category.isVisible && !category.subItems?.length) {
        continue
      }

      // Check if category has any visible items
      const hasVisibleItems = category.subItems.some(
        (subcat) => subcat.isVisible && subcat.subItems?.some((item) => item.isVisible)
      )

      if (hasVisibleItems) {
        md += '\n<div style="page-break-inside: avoid;" markdown="1">\n'
        md += category.catName ? `\n\n## ${category.catName}` : ''
      } else {
        md += category.catName ? `\n\n## ${category.catName}` : ''
      }

      for (const subcategory of category.subItems) {
        if (!includeHidden && !subcategory.isVisible && !subcategory.subItems?.length) {
          continue
        }

        md += '\n<div style="page-break-inside: avoid;" markdown="1">\n'
        md += subcategory.subcatName ? `\n### ${subcategory.subcatName}\n` : ''

        for (const item of subcategory.subItems) {
          if (!includeHidden && !item.isVisible && !item.subItems?.length) {
            continue
          }

          const hasSubVariants =
            item.subVariant1?.values?.length ||
            item.subVariant2?.values?.length ||
            item.subVariant3?.values?.length

          const mainPrice = hasSubVariants ? '' : item.price
          const mainName =
            includeSku && !hasSubVariants ? `#${item.sku} ${item.itemName}` : item.itemName

          md += item.itemName
            ? `\n- **${mainName}**${
                mainPrice ? ` - ${mainPrice} ${locale.measureUnits.currency}` : ''
              }`
            : ''

          if (item.subVariant1?.values?.length) {
            md += `\n  ${item.subVariant1.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.subVariant2?.values?.length) {
            md += `\n  ${item.subVariant2.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.subVariant3?.values?.length) {
            md += `\n  ${item.subVariant3.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.description) {
            md += `\n  - *${item.description}*`
          }
        }
        md += '\n</div>\n'
      }

      if (hasVisibleItems) {
        md += '\n</div>\n'
      }
    }

    return md
  }

  const packMdForKitchen = (locale, includeHidden = false) => {
    let md = '| SKU | Item Name | Price |\n|-----|-----------|-------|\n'

    for (const category of locale.subItems) {
      if (!includeHidden && !category.isVisible) continue

      for (const subcategory of category.subItems) {
        if (!includeHidden && !subcategory.isVisible) continue

        for (const item of subcategory.subItems) {
          if (!includeHidden && !item.isVisible) continue

          const hasSubvariants =
            item.subVariant1?.values?.length ||
            item.subVariant2?.values?.length ||
            item.subVariant3?.values?.length

          md += `| ${item.sku} | ${item.itemName} | ${
            hasSubvariants ? '' : `${item.price} ${locale.measureUnits.currency}`
          } |\n`

          if (item.subVariant1?.values?.length) {
            item.subVariant1.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }

          if (item.subVariant2?.values?.length) {
            item.subVariant2.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }

          if (item.subVariant3?.values?.length) {
            item.subVariant3.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }
        }
      }
    }

    return md
  }

  const handleSaveMdZip = async () => {
    const transformData = (data, includeHidden = false) => {
      let mds = []

      for (const locale of data.content) {
        const mdWithoutSkuInName = packMd(locale, includeHidden, false)
        const mdWithSkuInName = packMd(locale, includeHidden, true)
        const mdForKitchen = packMdForKitchen(locale, includeHidden)
        mds.push({ lang: locale.lang, md: mdWithoutSkuInName, includeSku: false })
        mds.push({ lang: locale.lang, md: mdWithSkuInName, includeSku: true })
        mds.push({ lang: locale.lang, md: mdForKitchen, includeSku: false, isKitchen: true })
      }

      return mds
    }

    const currentMenu = await getMenu(token, user.sub, id)
    const mds = transformData(currentMenu.data)

    let filenamesArray = []
    for (const md of mds) {
      const mdLang = md.lang === defaultLanguage ? `${md.lang}_default` : md.lang
      const mdFileName = `menu_${md.includeSku ? 'sku_' : ''}${
        md.isKitchen ? 'kitchen_' : ''
      }${mdLang}.md`
      const mdBlob = new Blob([md.md], {
        type: 'text/markdown;charset=utf-8',
      })
      zip.file(mdFileName, mdBlob)
      filenamesArray.push(mdFileName)
    }

    const zipResult = await zip.generateAsync({ type: 'blob' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipResult)
    a.setAttribute('download', 'menu-markdowns.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    filenamesArray.forEach((item) => zip.remove(item))
  }

  const handleSavePdfZip = async () => {
    return await generatePdfZip(
      token,
      setPdfWidth,
      user,
      id,
      usePDFMenu,
      PDF_WIDTH_A4,
      PDF_HEIGHT,
      false,
      () => true,
      'full',
      'A4',
      false
    )
  }

  const handleSaveDrinksPdfZip = async () => {
    return await generatePdfZip(
      token,
      setPdfWidth,
      user,
      id,
      usePDFMenu,
      PDF_WIDTH_A4,
      PDF_HEIGHT,
      true,
      (catName) => catName === 'Drinks' || catName === 'Spirits',
      'drinks',
      'A4'
    )
  }

  const handleSaveFoodsPdfZip = async () => {
    return await generatePdfZip(
      token,
      setPdfWidth,
      user,
      id,
      usePDFMenu,
      PDF_WIDTH_A4,
      PDF_HEIGHT,
      false,
      (catName) => catName !== 'Drinks' && catName !== 'Spirits',
      'foods',
      'A4'
    )
  }

  const handleSavePdfA5Zip = async () => {
    return await generatePdfZip(
      token,
      setPdfWidth,
      user,
      id,
      usePDFMenu,
      PDF_WIDTH_A5,
      PDF_HEIGHT_A5,
      false,
      (catName) => catName === 'Drinks' || catName === 'Spirits',
      'drinks',
      'A5'
    )
  }

  const handleSaveAllPdfsZip = async () => {
    const allZip = new JSZip()

    const pdfsBlobMain = await handleSavePdfZip()
    const pdfsBlobDrinks = await handleSaveDrinksPdfZip()
    const pdfsBlobFoods = await handleSaveFoodsPdfZip()
    const pdfsBlobA5 = await handleSavePdfA5Zip()

    if (pdfsBlobMain.length) {
      pdfsBlobMain.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobDrinks.length) {
      pdfsBlobDrinks.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobFoods.length) {
      pdfsBlobFoods.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobA5.length) {
      pdfsBlobA5.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }

    const zipBlob = await allZip.generateAsync({ type: 'blob' })

    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipBlob)
    a.setAttribute('download', 'all-menus.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const getEditMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={() => handleRedirect()}>
          <IconPen color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_EDIT')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getCloneMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={handleCloneMenu}>
          <IconCloneMenu color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_CLONE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getDeleteMenuButton = () => (
    <>
      {data ? (
        <div
          className='v2-button sm'
          onClick={() => {
            setShowRemoveModal({
              show: true,
              name: alias,
              kind: t('CON_MODAL_DELETE_MENU'),
              cbName: handleDeleteMenu,
              cbArgs: [],
            })
          }}
        >
          <IconRemoveItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_DELETE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getLogo = () => (
    <div className='logo'>
      {data ? (
        <>
          {logo ? (
            <img src={`${REACT_APP_CLOUDINARY_LOGO_PATH}${logo}`} alt='logo' />
          ) : (
            <DefaultLogo
              width={isDesktop ? '300px' : '100px'}
              height={isDesktop ? '300px' : '100px'}
            />
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  )

  const getTranslationsList = () => {
    return (
      <>
        {data
          ? translations.length > 1 && (
              <div
                aria-expanded={isOpen}
                className={`translations${isOpen ? ' open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className='translations-title'>
                  <h4>{t('MANAGER_MENU_ITEM_DROPDOWN_TEXT')}</h4>
                </div>
                {translations
                  .filter((item) => item.lang !== defaultLanguage)
                  .map((translation, t) => (
                    <MenuLanguageBlock
                      key={t}
                      menuId={id}
                      langName={translation.lang}
                      isPub={translation.isPub}
                      url={`${url}?lang=${translation.lang}`}
                      handleSaveQrZip={handleSaveQrZip}
                      handleSaveMdZip={handleSaveMdZip}
                      handleSaveAllPdfsZip={handleSaveAllPdfsZip}
                    />
                  ))}
              </div>
            )
          : null}
      </>
    )
  }

  const getMobileItem = () => (
    <div className='content-inner'>
      <div className='default-content'>
        <div className='content'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          {getEditMenuButton()}
        </div>
        {getLogo()}
      </div>
      <MenuLanguageBlock
        menuId={id}
        langName={defaultLanguage}
        isPub={!!events?.publishedAt}
        url={url}
        handleSaveQrZip={handleSaveQrZip}
        handleSaveMdZip={handleSaveMdZip}
        handleSaveAllPdfsZip={handleSaveAllPdfsZip}
      />
      {getTranslationsList()}
      <div className='delete-clone-btns'>
        {getDeleteMenuButton()}
        {getCloneMenuButton()}
      </div>
      <TheIndicator events={events} />
    </div>
  )

  const getDesktopItem = () => (
    <>
      <div className='content-block'>
        <div className='name-and-edit'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          <div className='button-block'>
            {getEditMenuButton()}
            {getCloneMenuButton()}
            {getDeleteMenuButton()}
          </div>
        </div>
        <MenuLanguageBlock
          menuId={id}
          langName={defaultLanguage}
          isPub={!!events?.publishedAt}
          url={url}
          handleSaveQrZip={handleSaveQrZip}
          handleSaveMdZip={handleSaveMdZip}
          handleSaveAllPdfsZip={handleSaveAllPdfsZip}
        />
        {getTranslationsList()}
      </div>
      <div className='logo-block'>
        <TheIndicator events={events} />
        {getLogo()}
      </div>
    </>
  )

  return (
    <>
      <div aria-expanded={isOpen} className='menu-item relative'>
        {isDesktop ? getDesktopItem() : getMobileItem()}
      </div>
      <div className='z-50 fixed w-full top-0 left-0 bg-white hidden'>
        {/* <div className='z-50 absolute w-full top-0 left-0 bg-white'> */}
        <div
          ref={usePDFMenu}
          style={{ maxWidth: `${pdfWidth}px` }}
          className='w-full mx-auto'
        ></div>
      </div>
    </>
  )
}

export default withRouter(MenuItem)
