import IconPaint from './IconPaint'
import IconAddItem from './IconAddItem'
import IconDrag from './IconDrag'
import IconDragOld from './IconDragOld'
import IconDragUp from './IconDragUp'
import IconDragDown from './IconDragDown'
import IconExport from './IconExport'
import IconCloneMenu from './IconCloneMenu'
import IconEyeHidden from './IconEyeHidden'
import IconEyeVisible from './IconEyeVisible'
import IconFacebook from './IconFacebook'
import IconWeb from './IconWeb'
import IconEmail from './IconEmail'
import IconLink from './IconLink'
import IconOpentable from './IconOpentable'
import IconLinktree from './IconLinktree'
import IconTelegram from './IconTelegram'
import IconGooglemaps from './IconGooglemaps'
import IconTiktok from './IconTiktok'
import IconWhatsapp from './IconWhatsapp'
import IconGlobe from './IconGlobe'
import IconImport from './IconImport'
import IconInstagram from './IconInstagram'
import IconNotes from './IconNotes'
import IconLogout from './IconLogout'
import IconPen from './IconPen'
import IconCopy from './IconCopy'
import IconPublish from './IconPublish'
import IconRemoveItem from './IconRemoveItem'
import IconTripadvisor from './IconTripadvisor'
import IconMenuListPlaceholder from './IconMenuListPlaceholder'
import IconStartTour from './IconStartTour'
import IconColumns2 from './IconColumns2'
import IconColumns3 from './IconColumns3'
import IconLockOpened from './IconLockOpened'
import IconLockClosed from './IconLockClosed'
import IconListCheck from './IconListCheck'
import IconListCross from './IconListCross'
import IconClose from './IconClose'

const icons = {
  IconPaint,
  IconAddItem,
  IconDrag,
  IconDragOld,
  IconDragUp,
  IconDragDown,
  IconExport,
  IconCloneMenu,
  IconEyeHidden,
  IconEyeVisible,
  IconFacebook,
  IconGlobe,
  IconImport,
  IconInstagram,
  IconNotes,
  IconLogout,
  IconPen,
  IconCopy,
  IconPublish,
  IconRemoveItem,
  IconTripadvisor,
  IconWeb,
  IconEmail,
  IconLink,
  IconOpentable,
  IconLinktree,
  IconTelegram,
  IconGooglemaps,
  IconTiktok,
  IconWhatsapp,
  IconMenuListPlaceholder,
  IconStartTour,
  IconColumns2,
  IconColumns3,
  IconLockOpened,
  IconLockClosed,
  IconListCheck,
  IconListCross,
  IconClose,
}

export default icons
