import React, { useState, useEffect, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { expandAllItems, rollUpAllItems } from '../helpers'
import { defaultCategory } from '../data/index'
import { CatDragWrapper } from './DragWrapper'
import Category from './Category'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'
import AddButton from './AddButton'

const { IconAddItem } = icons

const ContentBlock = () => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals, setIsLocalsLoading } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage, showList } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setShowList } = useContext(ConstructorSetterContext)
  // console.log({ menuLocals })
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [langData, setLangData] = useState(null)

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      const tempLangData = menuLocals.find((langObj) => langObj.lang === currentLanguage)
      setLangData(tempLangData)
    }
  }, [menuLocals, currentLanguage])

  useEffect(() => {
    setTimeout(() => setIsLocalsLoading(false), 0)
    // eslint-disable-next-line
  }, [langData, showList])

  const getExpandRollUpBlock = () => (
    <div className='expand-rollup-block'>
      {langData ? (
        <>
          <div
            className='expand-all-btn v2-button sm v2-button-outline'
            onClick={
              isLocalsLoading
                ? null
                : () => {
                    setIsLocalsLoading(true)
                    expandAllItems(showList, setShowList, setIsLocalsLoading)
                  }
            }
          >
            {t('CON_CONTENT_BUTTON_EXPAND')}
          </div>

          <div
            className='rollup-all-btn v2-button sm v2-button-outline'
            onClick={
              isLocalsLoading
                ? null
                : () => {
                    setIsLocalsLoading(true)
                    rollUpAllItems(showList, setShowList, setIsLocalsLoading)
                  }
            }
          >
            {t('CON_CONTENT_BUTTON_COLLAPSE')}
          </div>
        </>
      ) : (
        <>
          <div className='expand-all-btn skeleton-button sm'>
            <Skeleton />
          </div>

          <div className='rollup-all-btn skeleton-button sm'>
            <Skeleton />
          </div>
        </>
      )}
    </div>
  )

  const getCatList = () => (
    <ul id='categories-container' className='categories-container'>
      {langData ? (
        langData.subItems?.map((item, i) => {
          return <Category key={i} catId={item.id} catIndex={i} />
        })
      ) : (
        <div className='skeleton-container'>
          <Skeleton />
        </div>
      )}
    </ul>
  )

  const handleCreateCategory = () => {
    const _defaultCategory = defaultCategory()
    const _menuLocals = JSON.parse(JSON.stringify(menuLocals))

    _menuLocals.map((local) => {
      local.subItems.push(_defaultCategory)
      return local
    })

    showList.push({ name: _defaultCategory.id, isOpen: true })
    setMenuLocals(_menuLocals)
    setShowList(showList)
    setIsThereUnsavedChanges(true)
  }

  return (
    <>
      <Row className='title'>
        {langData ? (
          <h2>{t('CON_CONTENT_TITLE')}</h2>
        ) : (
          <h2>
            <Skeleton />
          </h2>
        )}
        <div className='title-inner'>{getExpandRollUpBlock()}</div>
      </Row>

      <Row className='menu-list' xl>
        <Col className='menu-list-inner' xl>
          <CatDragWrapper>{getCatList()}</CatDragWrapper>
        </Col>
      </Row>

      <Row className='control-box'>
        {langData ? (
          <>
            {currentLanguage === defaultLanguage ? (
              <AddButton
                onClick={
                  isLocalsLoading
                    ? null
                    : () => {
                        handleCreateCategory()
                      }
                }
                textKey='CON_CONTENT_CATEGORY_TEXT'
              />
            ) : (
              <div />
            )}
          </>
        ) : (
          <div className='skeleton-button sm'>
            <Skeleton />
          </div>
        )}
        {getExpandRollUpBlock()}
      </Row>
    </>
  )
}

export default ContentBlock
