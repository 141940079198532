import {
  PDF_HEIGHT,
  PDF_WIDTH_A4,
  PDF_TOP_MARGIN,
  PDF_HEIGHT_A5,
  PDF_WIDTH_A5,
  PDF_TOP_MARGIN_A5,
} from './constants'

export const createPageContent = (page, pageIndex, type = 'A4') => {
  const { data, isThreeColumnLayout } = page

  const pageHeight = type === 'A5' ? PDF_HEIGHT_A5 : PDF_HEIGHT
  const pageTopMargin = type === 'A5' ? PDF_TOP_MARGIN_A5 : PDF_TOP_MARGIN
  const columnWidths =
    type === 'A5'
      ? ['w-[50%]', 'w-[50%]']
      : isThreeColumnLayout
      ? ['w-[32%]', 'w-[32%]', 'w-[32%]']
      : ['w-[48%]', 'w-[48%]']

  return `
    <div style="height: ${pageHeight}px; margin-top: ${pageTopMargin}px;" class='break-after-page relative'>
      <div class="flex flex-row justify-between gap-8">
        <div class="${columnWidths[0]} box-border">${data[0]?.join('')}</div>
        <div class="${columnWidths[1]} box-border">${data[1]?.join('')}</div>
        ${
          columnWidths[2]
            ? `<div class="${columnWidths[2]} box-border">${data[2]?.join('')}</div>`
            : ''
        }
      </div>
      <div class="text-center absolute -bottom-20 border-t border-t-gray-300 pt-2 left-0 w-full">
        <p class="text-right font-lato text-[9px] text-gray-500">Page ${pageIndex + 1}</p>
      </div>
    </div>
    `
}
