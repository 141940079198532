import { getContentHeight } from './getContentHeight'
import { hasSubVariants } from './hasSubVariants'

export const createItemContent = (
  subItem,
  isThreeColumnLayout,
  isLastItem,
  currentMenu,
  isListMode = false
) => {
  const currency = currentMenu?.data?.content?.[0]?.measureUnits?.currency || 'N/A'

  if (!subItem || !currentMenu?.data?.content) {
    console.error('[createItemContent] Missing required data.', { subItem, currentMenu })
    return { content: '', height: 0 }
  }

  const groupVariants = (variants) => {
    if (!variants || !variants.length) return ''

    const visibleVariants = variants.filter((variant) => variant.isVisible)
    if (!visibleVariants.length) return ''

    const groupedByPrice = {}
    visibleVariants.forEach((variant) => {
      const price = variant.price || '0'
      if (!groupedByPrice[price]) {
        groupedByPrice[price] = []
      }
      groupedByPrice[price].push(variant.subVariantName)
    })

    return Object.entries(groupedByPrice)
      .map(([price, names]) => {
        const namesString = names.join(' / ')
        return `<div class="flex justify-between gap-5">
                  <p class="font-lato ${
                    isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                  } text-black font-semibold leading-normal">${namesString}</p>
                  ${
                    price !== '0' && price
                      ? `<p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${price} ${currency}</p>`
                      : ''
                  }
                </div>`
      })
      .join('')
  }

  const content = `
    <div class="w-full ${isLastItem ? 'mb-4' : 'mb-2'} ${
    isThreeColumnLayout ? 'max-w-[220px]' : 'max-w-[345px]'
  }">
      <div class="flex flex-row justify-between gap-5 items-center">
        <h4 class="font-lato text-black ${
          isThreeColumnLayout ? 'text-[12px] max-w-[220px]' : 'text-[14px] max-w-[345px]'
        } font-bold leading-normal mb-0">${subItem.itemName}</h4>
        ${
          hasSubVariants(subItem)
            ? ''
            : subItem.price &&
              `<p class="font-lato ${
                isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
              } text-black leading-normal whitespace-nowrap">${subItem.price} ${currency}</p>`
        }
      </div>

      ${
        subItem.description &&
        `<p class="mt-1.5 ml-3 font-lato italic leading-normal ${
          isThreeColumnLayout ? 'text-[11px]' : 'text-[13px]'
        } text-gray-500">${subItem.description}</p>`
      }

      ${
        subItem.subVariant1 && subItem.subVariant1.values.length
          ? isListMode
            ? `<div class="ml-3 mt-1">${groupVariants(subItem.subVariant1.values)}</div>`
            : subItem.subVariant1.values
                .map((variant) =>
                  variant.isVisible
                    ? `<div class="ml-3 flex justify-between gap-5 mt-1">
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                         } text-black font-semibold leading-normal">${variant.subVariantName}</p>
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                         } text-black font-normal leading-normal whitespace-nowrap">${
                        variant.price
                      } ${currency}</p>
                       </div>`
                    : ''
                )
                .join('')
          : ''
      }

      ${
        subItem.subVariant2 && subItem.subVariant2.values.length
          ? isListMode
            ? `<div class="ml-3 mt-1">${groupVariants(subItem.subVariant2.values)}</div>`
            : subItem.subVariant2.values
                .map((variant) =>
                  variant.isVisible
                    ? `<div class="ml-3 flex justify-between gap-5 mt-1">
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                         } text-black font-semibold leading-normal">${variant.subVariantName}</p>
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                         } text-black font-normal leading-normal whitespace-nowrap">${
                        variant.price
                      } ${currency}</p>
                       </div>`
                    : ''
                )
                .join('')
          : ''
      }

      ${
        subItem.subVariant3 && subItem.subVariant3.values.length
          ? isListMode
            ? `<div class="ml-3 mt-1">${groupVariants(subItem.subVariant3.values)}</div>`
            : subItem.subVariant3.values
                .map((variant) =>
                  variant.isVisible
                    ? `<div class="ml-3 flex justify-between gap-5 mt-1">
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                         } text-black font-semibold leading-normal">${variant.subVariantName}</p>
                         <p class="font-lato ${
                           isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                         } text-black font-normal leading-normal whitespace-nowrap">${
                        variant.price
                      } ${currency}</p>
                       </div>`
                    : ''
                )
                .join('')
          : ''
      }
    </div>
  `

  return {
    content,
    height: getContentHeight(content),
  }
} // import { getContentHeight } from './getContentHeight'
// import { hasSubVariants } from './hasSubVariants'

// export const createItemContent = (
//   subItem,
//   isThreeColumnLayout,
//   isLastItem,
//   currentMenu,
//   isListMode = false
// ) => {
//   const currency = currentMenu?.data?.content?.[0]?.measureUnits?.currency || 'N/A'

//   if (!subItem || !currentMenu?.data?.content) {
//     console.error('[createItemContent] Missing required data.', { subItem, currentMenu })
//     return { content: '', height: 0 }
//   }

//   const groupVariants = (variants) => {
//     if (!variants || !variants.length) return ''

//     const visibleVariants = variants.filter((variant) => variant.isVisible)
//     if (!visibleVariants.length) return ''

//     const groupedByPrice = {}
//     visibleVariants.forEach((variant) => {
//       const price = variant.price || '0'
//       if (!groupedByPrice[price]) {
//         groupedByPrice[price] = []
//       }
//       groupedByPrice[price].push(variant.subVariantName)
//     })

//     const variantStrings = Object.entries(groupedByPrice).map(([price, names]) => {
//       const namesString = names.join(' / ')
//       return price === '0' || !price ? namesString : `${namesString} (${price} ${currency})`
//     })

//     return variantStrings.join('; ')
//   }

//   const content = `
//     <div class="w-full ${isLastItem ? 'mb-4' : 'mb-2'} ${
//     isThreeColumnLayout ? 'max-w-[220px]' : 'max-w-[345px]'
//   }">
//       <div class="flex flex-row justify-between gap-5 items-center">
//         <h4 class="font-lato text-black ${
//           isThreeColumnLayout ? 'text-[12px] max-w-[220px]' : 'text-[14px] max-w-[345px]'
//         } font-bold leading-normal mb-0">${subItem.itemName}</h4>
//         ${
//           hasSubVariants(subItem)
//             ? ''
//             : subItem.price &&
//               `<p class="font-lato ${
//                 isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
//               } text-black leading-normal whitespace-nowrap">${subItem.price} ${currency}</p>`
//         }
//       </div>

//       ${
//         subItem.description &&
//         `<p class="mt-1.5 ml-3 font-lato italic leading-normal ${
//           isThreeColumnLayout ? 'text-[11px]' : 'text-[13px]'
//         } text-gray-500">${subItem.description}</p>`
//       }

//       ${
//         subItem.subVariant1 && subItem.subVariant1.values.length
//           ? isListMode
//             ? `<div class="ml-3 mt-1">
//                  <p class="font-lato ${
//                    isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                  } text-black font-semibold leading-normal">${groupVariants(
//                 subItem.subVariant1.values
//               )}</p>
//                </div>`
//             : subItem.subVariant1.values
//                 .map((variant) =>
//                   variant.isVisible
//                     ? `<div class="ml-3 flex justify-between gap-5 mt-1">
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                          } text-black font-semibold leading-normal">${variant.subVariantName}</p>
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
//                          } text-black font-normal leading-normal whitespace-nowrap">${
//                         variant.price
//                       } ${currency}</p>
//                        </div>`
//                     : ''
//                 )
//                 .join('')
//           : ''
//       }

//       ${
//         subItem.subVariant2 && subItem.subVariant2.values.length
//           ? isListMode
//             ? `<div class="ml-3 mt-1">
//                  <p class="font-lato ${
//                    isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                  } text-black font-semibold leading-normal">${groupVariants(
//                 subItem.subVariant2.values
//               )}</p>
//                </div>`
//             : subItem.subVariant2.values
//                 .map((variant) =>
//                   variant.isVisible
//                     ? `<div class="ml-3 flex justify-between gap-5 mt-1">
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                          } text-black font-semibold leading-normal">${variant.subVariantName}</p>
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
//                          } text-black font-normal leading-normal whitespace-nowrap">${
//                         variant.price
//                       } ${currency}</p>
//                        </div>`
//                     : ''
//                 )
//                 .join('')
//           : ''
//       }

//       ${
//         subItem.subVariant3 && subItem.subVariant3.values.length
//           ? isListMode
//             ? `<div class="ml-3 mt-1">
//                  <p class="font-lato ${
//                    isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                  } text-black font-semibold leading-normal">${groupVariants(
//                 subItem.subVariant3.values
//               )}</p>
//                </div>`
//             : subItem.subVariant3.values
//                 .map((variant) =>
//                   variant.isVisible
//                     ? `<div class="ml-3 flex justify-between gap-5 mt-1">
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
//                          } text-black font-semibold leading-normal">${variant.subVariantName}</p>
//                          <p class="font-lato ${
//                            isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
//                          } text-black font-normal leading-normal whitespace-nowrap">${
//                         variant.price
//                       } ${currency}</p>
//                        </div>`
//                     : ''
//                 )
//                 .join('')
//           : ''
//       }
//     </div>
//   `

//   return {
//     content,
//     height: getContentHeight(content),
//   }
// }
