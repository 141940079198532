/* eslint-disable comma-dangle */
const uuid = require('uuid').v4

export const availableLanguages = [
  { name: 'English', short: 'en' },
  { name: 'Ukrainian', short: 'uk' },
  { name: 'German', short: 'de' },
  { name: 'Czech', short: 'cs' },
  { name: 'Russian', short: 'ru' },
  { name: 'Italian', short: 'it' },
  { name: 'French', short: 'fr' },
  { name: 'Polish', short: 'pl' },
]
export const placeholders = {
  cat: 'Food',
  subcat: 'Salads',
  item: 'Caesar',
  description: 'Romaine lettuce, Parmesan cheese, Croutons, Caesar dressing',
  subVariantGroupName: 'Group name',
  subVariant: 'Variant name',
  restname: 'Menu',
  currency: '$',
  mass: 'g',
  quantity: 'bottle',
  time: 'min',
  volume: 'ml',
}

export const defaultItem = () => {
  return {
    id: uuid(),
    isVisible: true,
    isListMode: false,
    itemName: '',
    description: '',
    weight: '100',
    measure: 'm',
    price: '100',
    sku: '',
    handle: '',
    image: '',
    subVariant1: {
      name: '',
      values: [],
    },
    subVariant2: {
      name: '',
      values: [],
    },
    subVariant3: {
      name: '',
      values: [],
    },
  }
}

export const defaultSubVariant = () => {
  return {
    id: uuid(),
    isVisible: true,
    // subVariantName: '(placeholder)',
    subVariantName: '',
    price: '',
    sku: '',
    isNew: true,
  }
}

export const defaultSubcategory = () => {
  return {
    id: uuid(),
    isVisible: true,
    subcatName: '',
    subItems: [],
  }
}

export const defaultCategory = () => {
  return {
    id: uuid(),
    isVisible: true,
    catName: '',
    isThreeColumnLayout: false,
    isFrozen: false,
    subItems: [],
  }
}
