import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import InputGroup from 'react-bootstrap/InputGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { findTargetById, renderTooltip, openizator, getMeasure } from '../helpers'
import UnitNameDebouncedInput from './UnitNameDebouncedInput'
import ItemDescriptionDebouncedInput from './ItemDescriptionDebouncedInput'
import ItemMeasureDebouncedInput from './ItemMeasureDebouncedInput'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop, useDragDown, useDragUp } from '../helpers/hooks'
import ItemImageLoader from './ItemImageLoader'
import AddButton from './AddButton'
import { ItemDragWrapper } from './DragWrapper'
import SubVariant from './SubVariant'
import { defaultItem, defaultSubVariant } from '../data/index'
import NameGroupDebouncedInput from './NameGroupDebouncedInput'
import { Form } from 'react-bootstrap'
const { IconRemoveItem, IconEyeVisible, IconEyeHidden, IconDragOld, IconListCheck, IconListCross } =
  icons

const Item = ({ itemId, catIndex, subcatIndex, itemIndex }) => {
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage, showList } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setShowList } = useContext(ConstructorSetterContext)

  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [defaultLangData, setDefaultLangData] = useState(null)
  const [langData, setLangData] = useState(null)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      setLangData(menuLocals.find((langObj) => langObj.lang === currentLanguage))
    }
  }, [menuLocals, currentLanguage])

  useEffect(() => {
    if (menuLocals && defaultLanguage) {
      const _defaultLangData = menuLocals.find((langObj) => langObj.lang === defaultLanguage)
      setDefaultLangData(_defaultLangData)
    }
  }, [menuLocals, defaultLanguage])

  const target = useRef(null)

  const dragDownBtn = useDragDown([catIndex, subcatIndex, itemIndex], itemId)
  const dragUpBtn = useDragUp([catIndex, subcatIndex, itemIndex], itemId)

  useEffect(() => {
    if (showList) {
      showList.forEach((item) => {
        if (item.name === itemId) {
          setIsOpen(item.isOpen)
        }
      })
    }
  }, [showList, itemId])

  const getValue = (u) => {
    let res
    switch (u) {
      case 0:
        res = 'm'
        break
      case 1:
        res = 'v'
        break
      case 2:
        res = 't'
        break
      case 3:
        res = 'q'
        break
      case 4:
        res = 'd'
        break
      case 5:
        res = 'l'
        break
      default:
        res = 'q'
        break
    }
    return res
  }

  const getSubVariantsList = () => {
    return (
      <ul className='subvariants'>
        {['subVariant1', 'subVariant2', 'subVariant3'].map((variantKey, vIndex) => {
          const variantData =
            langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[variantKey]

          if (!variantData || variantData.values.length === 0) return null

          const getNameGroupHint = () => {
            const showHint =
              currentLanguage !== defaultLanguage &&
              defaultLangData &&
              defaultLangData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                `subVariant${vIndex + 1}`
              ]?.name

            return (
              <>
                {showHint ? (
                  <div className='hint-row'>
                    <InputGroup size='sm' className='hint-content'>
                      <InputGroup.Prepend>
                        <InputGroup.Text>{t('CON_CONTENT_INPUT_NAME_TEXT')}</InputGroup.Text>
                      </InputGroup.Prepend>
                      <div className='hint-container'>
                        {defaultLangData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[
                          itemIndex
                        ]?.[`subVariant${vIndex + 1}`]?.name || ''}
                      </div>
                    </InputGroup>
                  </div>
                ) : null}
              </>
            )
          }

          return (
            <li key={variantKey}>
              {variantData.values.length > 0 && (
                <>
                  {getNameGroupHint()}
                  <InputGroup size='sm' className='variant-name-input'>
                    <InputGroup.Prepend>
                      <InputGroup.Text>{t('CON_CONTENT_INPUT_NAME_TEXT')}</InputGroup.Text>
                    </InputGroup.Prepend>

                    <NameGroupDebouncedInput
                      incomingName='variantName'
                      incomingValue={variantData.name}
                      incomingId={itemId}
                      catIndex={catIndex}
                      subcatIndex={subcatIndex}
                      itemIndex={itemIndex}
                      variantGroup={vIndex + 1}
                    />
                  </InputGroup>
                </>
              )}

              {variantData.values.map((variant, v) => (
                <SubVariant
                  key={variant.id}
                  variantId={variant.id}
                  name={variant.subVariantName}
                  sku={variant.sku}
                  price={variant.price}
                  isVisible={variant.isVisible}
                  measureUnits={langData.measureUnits}
                  catIndex={catIndex}
                  subcatIndex={subcatIndex}
                  itemIndex={itemIndex}
                  variantIndex={v}
                  variantGroup={vIndex + 1}
                  itemId={itemId}
                />
              ))}
            </li>
          )
        })}
      </ul>
    )
  }

  const handleToggleVisibility = () => {
    const defaultIsVisible = findTargetById(defaultLangData, itemId).isVisible
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, itemId)
        currentUnit.isVisible = !defaultIsVisible
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, itemId)
          currentUnit.isVisible = !currentUnit.isVisible
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteItem = () => {
    menuLocals.forEach((local) => {
      local.subItems[catIndex].subItems[subcatIndex].subItems = langData.subItems[
        catIndex
      ].subItems[subcatIndex].subItems.filter((item) => item.id !== itemId)
    })
    setMenuLocals(menuLocals)
    setIsThereUnsavedChanges(true)
    return true
  }

  const handleCreateSubVariant = () => {
    const _menuLocals = JSON.parse(JSON.stringify(menuLocals))

    _menuLocals.forEach((local) => {
      const item = local.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]

      if (item) {
        if (!item.subVariant1) item.subVariant1 = { name: '', values: [] }
        if (!item.subVariant2) item.subVariant2 = { name: '', values: [] }
        if (!item.subVariant3) item.subVariant3 = { name: '', values: [] }

        const newVariant = defaultSubVariant()

        if (item.subVariant1) {
          item.subVariant1.values.push(newVariant)
        } else if (item.subVariant2) {
          item.subVariant2.values.push(newVariant)
        } else if (item.subVariant3) {
          item.subVariant3.values.push(newVariant)
        }
      }
    })

    setMenuLocals([..._menuLocals])
    setIsThereUnsavedChanges(true)

    console.log('New menuLocals:', _menuLocals)
  }

  const handleToggleListMode = () => {
    console.log('Switch isListMode for itemId:', itemId)
    const _menuLocals = menuLocals.map((local) => {
      if (local.lang === currentLanguage || currentLanguage === defaultLanguage) {
        const currentItem = findTargetById(local, itemId)
        if (currentItem) {
          currentItem.isListMode = !currentItem.isListMode
          console.log('New isListMode value:', currentItem.isListMode)
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleItemMeasureChange = (value, incomingId) => {
    console.log('measureValue', value, incomingId)
    const newData = menuLocals.map((menu, m) => {
      const langTargetItem = findTargetById(menu, incomingId)
      langTargetItem.measure = value
      return menu
    })
    setMenuLocals(newData)
    setIsThereUnsavedChanges(true)
  }

  const getHideButton = () => {
    const buttonItself = (
      <label className='btn-hide input-label' htmlFor={`${currentLanguage}_${itemId}`}>
        {langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].isVisible ? (
          <IconEyeVisible color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        ) : (
          <IconEyeHidden color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        )}
        <input
          type='checkbox'
          id={`${currentLanguage}_${itemId}`}
          name='isVisible'
          value={langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].isVisible}
          onClick={isLocalsLoading ? null : (e) => handleToggleVisibility(e)}
        />
      </label>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_HIDE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDeleteButton = () => {
    const buttonItself = (
      <div
        className='btn-delete'
        onClick={() => {
          setShowRemoveModal({
            show: true,
            name: langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].itemName,
            kind: t('CON_MODAL_DELETE_ITEM'),
            cbName: handleDeleteItem,
            cbArgs: [],
          })
        }}
      >
        <IconRemoveItem color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DELETE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getToggleListModeButton = () => {
    const isListMode = findTargetById(langData, itemId)?.isListMode || false

    const tooltipText = isListMode
      ? t('CON_CONTENT_TOOLTIP_TOGGLE_GROUP')
      : t('CON_CONTENT_TOOLTIP_TOGGLE_UNGROUP')

    const buttonItself = (
      <div className='btn-toggle-list-mode' onClick={handleToggleListMode}>
        {isListMode ? (
          <IconListCheck color='#ff7a45' width='34px' />
        ) : (
          <IconListCross color='#ff7a45' width='34px' />
        )}
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(tooltipText)}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDefaultLanguageButtons = () =>
    currentLanguage === defaultLanguage && (
      <Col className='default-language-buttons'>
        {isDesktop ? (
          <>
            <div className='toggleHide'>{getHideButton()}</div>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DRAG'))}
              target={target.current}
            >
              <span className='drag'>
                <IconDragOld color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='30px' />
              </span>
            </OverlayTrigger>
          </>
        ) : (
          <>
            {getDeleteButton()}
            {getHideButton()}
            {dragUpBtn}
          </>
        )}
      </Col>
    )

  const getHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].itemName
    return (
      <>
        {showHint ? (
          <div className='hint-row'>
            <InputGroup size='sm' className='hint-content'>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_CONTENT_ITEM_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <div className='hint-container'>
                {
                  defaultLangData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex]
                    .itemName
                }
              </div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const getNameAndButton = () => (
    <div className='name-and-delete'>
      {getHint()}
      {!isDesktop && getDefaultLanguageButtons()}

      <div className='actions-block'>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('CON_CONTENT_ITEM_TEXT')}</InputGroup.Text>
          </InputGroup.Prepend>
          <UnitNameDebouncedInput
            incomingKind='item'
            incomingName='itemName'
            incomingValue={
              langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].itemName
            }
            incomingId={itemId}
            incomingIndex={itemIndex}
            incomingParentIndex={subcatIndex}
            incomingGrandParentIndex={catIndex}
          />
        </InputGroup>
        {currentLanguage === defaultLanguage && (isDesktop ? getDeleteButton() : dragDownBtn)}
        {currentLanguage !== defaultLanguage && getHideButton()}
      </div>
    </div>
  )

  const getHeader = () => (
    <Row
      aria-expanded={isOpen}
      className={`unit-header expandable${isOpen ? ' open' : ''}${
        currentLanguage !== defaultLanguage ? ' translated' : ''
      }`}
    >
      <div className='expand-btn' onClick={() => openizator(itemId, showList, setShowList)} />
      {getNameAndButton()}
      {isDesktop && getDefaultLanguageButtons()}
    </Row>
  )

  const getMeasureBlock = () => (
    <Col className='measure-block'>
      <InputGroup size='sm'>
        <InputGroup.Prepend>
          <InputGroup.Text>{t('CON_CONTENT_ITEM_MEASURE')}</InputGroup.Text>
        </InputGroup.Prepend>

        {currentLanguage === defaultLanguage ? (
          <>
            <ItemMeasureDebouncedInput
              incomingName='weight'
              incomingValue={
                langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].weight
              }
              incomingId={itemId}
              incomingIndex={itemIndex}
              incomingParentIndex={subcatIndex}
              incomingGrandParentIndex={catIndex}
            />
            <DropdownButton
              size='sm'
              variant='outline-info'
              title={getMeasure(
                langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].measure,
                langData.measureUnits
              )}
            >
              {[
                langData.measureUnits.mass,
                langData.measureUnits.volume,
                langData.measureUnits.time,
                langData.measureUnits.quantity,
                langData.measureUnits.diameter,
                langData.measureUnits['length'],
              ].map((unit, u) => (
                <Dropdown.Item
                  key={u}
                  eventKey={u}
                  as='button'
                  value={getValue(u)}
                  name='measure'
                  onClick={(e) => {
                    handleItemMeasureChange(e.target.value, itemId)
                  }}
                >
                  {unit}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </>
        ) : (
          <>
            <Form.Control
              size='sm'
              type='text'
              value={
                langData?.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]
                  ?.weight || ''
              }
              disabled
            />

            <InputGroup.Append>
              <InputGroup.Text>
                {getMeasure(
                  langData?.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]
                    ?.measure,
                  langData?.measureUnits
                )}
              </InputGroup.Text>
            </InputGroup.Append>
          </>
        )}
      </InputGroup>
    </Col>
  )

  const hasSubVariants = () => {
    const item = langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]
    return (
      (item?.subVariant1?.values?.length ?? 0) > 0 ||
      (item?.subVariant2?.values?.length ?? 0) > 0 ||
      (item?.subVariant3?.values?.length ?? 0) > 0
    )
  }

  const getPriceBlock = () => (
    <Col className='price-block'>
      <InputGroup size='sm'>
        <InputGroup.Prepend>
          <InputGroup.Text>{t('CON_CONTENT_ITEM_PRICE')}</InputGroup.Text>
        </InputGroup.Prepend>

        {currentLanguage === defaultLanguage ? (
          <ItemMeasureDebouncedInput
            incomingName='price'
            incomingValue={
              langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].price
            }
            incomingId={itemId}
            incomingIndex={itemIndex}
            incomingParentIndex={subcatIndex}
            incomingGrandParentIndex={catIndex}
            disabled={hasSubVariants()}
          />
        ) : (
          <Form.Control
            size='sm'
            type='text'
            value={
              langData?.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]
                ?.price || ''
            }
            disabled
          />
        )}
        <InputGroup.Append>
          <InputGroup.Text>{langData?.measureUnits?.currency || ''}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  )

  const getSkuBlock = () => (
    <Col className='sku-block'>
      <InputGroup size='sm'>
        <InputGroup.Prepend>
          <InputGroup.Text>{t('CON_CONTENT_ITEM_SKU')}</InputGroup.Text>
        </InputGroup.Prepend>

        {currentLanguage === defaultLanguage ? (
          <ItemMeasureDebouncedInput
            incomingName='sku'
            incomingValue={
              langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].sku
            }
            incomingId={itemId}
            incomingIndex={itemIndex}
            incomingParentIndex={subcatIndex}
            incomingGrandParentIndex={catIndex}
            disabled={hasSubVariants()}
          />
        ) : (
          <Form.Control
            size='sm'
            type='text'
            value={
              langData?.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]?.sku ||
              ''
            }
            disabled
          />
        )}
      </InputGroup>
    </Col>
  )

  const getDescriptionHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].description
    return (
      <>
        {showHint ? (
          <div className='hint-row hint-row-description'>
            <InputGroup size='sm' className='hint-content'>
              <div className='hint-container'>
                {
                  defaultLangData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex]
                    .description
                }
              </div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const getContent = () => (
    <Collapse in={isOpen}>
      <Container className='unit-content' fluid>
        {getDescriptionHint()}

        <Card className='description'>
          <Card.Header>{t('CON_CONTENT_ITEM_DESCRIPTION')}</Card.Header>
          <ItemDescriptionDebouncedInput
            incomingValue={
              langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].description
            }
            incomingId={itemId}
            incomingIndex={itemIndex}
            incomingParentIndex={subcatIndex}
            incomingGrandParentIndex={catIndex}
          />
        </Card>

        <Row className='measurements'>
          <ItemImageLoader
            incomingId={itemId}
            incomingIndex={itemIndex}
            incomingParentIndex={subcatIndex}
            incomingGrandParentIndex={catIndex}
          />
          {getToggleListModeButton()}

          {getSkuBlock()}
          {getMeasureBlock()}
          {getPriceBlock()}
        </Row>
        {getSubVariantsList()}
        {currentLanguage === defaultLanguage && (
          <Row className='control-box'>
            <AddButton
              id='add-item-button'
              onClick={handleCreateSubVariant}
              textKey='CON_CONTENT_SUBVARIANT_TEXT'
            />
          </Row>
        )}
      </Container>
    </Collapse>
  )

  return langData ? (
    <Container
      className={`item-container ${
        langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex].isVisible
          ? ''
          : 'grayed'
      }`}
      as='li'
      id={itemId}
      fluid
    >
      {getHeader()}
      {getContent()}
    </Container>
  ) : null
}

export default Item
