import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { SubcatDragWrapper } from './DragWrapper'
import { findTargetById, renderTooltip, openizator } from '../helpers'
import { defaultSubcategory } from '../data/index'
import UnitNameDebouncedInput from './UnitNameDebouncedInput'
import SubCategory from './SubCategory'
import { useAuth0 } from '@auth0/auth0-react'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop, useDragDown, useDragUp } from '../helpers/hooks'
import AddButton from './AddButton'

const {
  IconAddItem,
  IconRemoveItem,
  IconEyeVisible,
  IconEyeHidden,
  IconDragOld,
  IconColumns2,
  IconColumns3,
  IconLockOpened,
  IconLockClosed,
} = icons

const Category = ({ catId, catIndex }) => {
  const { user, isAuthenticated } = useAuth0()
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage, showList } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setShowList } = useContext(ConstructorSetterContext)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [defaultLangData, setDefaultLangData] = useState(null)
  const [langData, setLangData] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (menuLocals && defaultLanguage) {
      const _defaultLangData = menuLocals.find((langObj) => langObj.lang === defaultLanguage)
      setDefaultLangData(_defaultLangData)
    }
  }, [menuLocals, defaultLanguage])

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      const _langData = menuLocals.find((langObj) => langObj.lang === currentLanguage)
      setLangData(_langData)
    }
  }, [menuLocals, currentLanguage])

  const target = useRef(null)

  const dragDownBtn = useDragDown([catIndex], catId)
  const dragUpBtn = useDragUp([catIndex], catId)

  useEffect(() => {
    if (showList) {
      showList.forEach((item) => {
        if (item.name === catId) {
          setIsOpen(item.isOpen)
        }
      })
    }
  }, [showList, catId])

  const isAdmin = useMemo(() => {
    return true
    if (user && isAuthenticated) {
      const userRolesArray = user[`${process.env.REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  const getSubcatList = () => (
    <ul className='subcategories'>
      {langData.subItems[catIndex]?.subItems?.map((item, n) => {
        return <SubCategory key={n} subcatId={item.id} catIndex={catIndex} subcatIndex={n} />
      })}
    </ul>
  )

  const handleToggleVisibility = () => {
    const defaultIsVisible = findTargetById(defaultLangData, catId).isVisible
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, catId)
        currentUnit.isVisible = !defaultIsVisible
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, catId)
          currentUnit.isVisible = !currentUnit.isVisible
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleToggleColumns = () => {
    const defaultIsThreeColumnLayout = findTargetById(defaultLangData, catId).isThreeColumnLayout
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, catId)
        currentUnit.isThreeColumnLayout = !defaultIsThreeColumnLayout
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, catId)
          currentUnit.isThreeColumnLayout = !currentUnit.isThreeColumnLayout
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteCategory = () => {
    menuLocals.forEach((local) => {
      local.subItems = local.subItems.filter((item) => item.id !== catId)
    })
    setMenuLocals(menuLocals)
    setIsThereUnsavedChanges(true)
    return true
  }

  const handleCreateSubcategory = () => {
    const _defaultSubcategory = defaultSubcategory()
    const _menuLocals = JSON.parse(JSON.stringify(menuLocals))

    _menuLocals.map((local) => {
      for (let category of local.subItems) {
        if (category.id === catId) {
          category.subItems.push({ ..._defaultSubcategory })
          break
        }
      }
      return local
    })

    showList.push({ name: _defaultSubcategory.id, isOpen: true })
    setShowList(showList)
    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const getHideButton = () => {
    const buttonItself = (
      <label className='btn-hide input-label' htmlFor={`${currentLanguage}_${catId}`}>
        {langData.subItems[catIndex].isVisible ? (
          <IconEyeVisible color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        ) : (
          <IconEyeHidden color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        )}
        <input
          type='checkbox'
          id={`${currentLanguage}_${catId}`}
          name='isVisible'
          value={langData.subItems[catIndex].isVisible}
          onClick={
            isLocalsLoading
              ? null
              : (e) => {
                  handleToggleVisibility(e)
                }
          }
        />
      </label>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_HIDE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDeleteButton = () => {
    const buttonItself = (
      <div
        className='btn-delete'
        onClick={
          isLocalsLoading
            ? null
            : () => {
                setShowRemoveModal({
                  show: true,
                  name: langData.subItems[catIndex].catName,
                  kind: t('CON_MODAL_DELETE_CATEGORY'),
                  cbName: handleDeleteCategory,
                  cbArgs: [],
                })
              }
        }
      >
        <IconRemoveItem color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DELETE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDefaultLanguageButtons = () =>
    currentLanguage === defaultLanguage && (
      <Col className='default-language-buttons'>
        {isDesktop ? (
          <>
            <div className='toggleHide'>{getHideButton()}</div>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DRAG'))}
              target={target.current}
            >
              <span className='drag'>
                <IconDragOld color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='30px' />
              </span>
            </OverlayTrigger>
          </>
        ) : (
          <>
            {getDeleteButton()}
            {getHideButton()}
            {dragUpBtn}
          </>
        )}
      </Col>
    )

  const getHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex].catName
    return (
      <>
        {showHint ? (
          <div className='hint-row'>
            <InputGroup size='sm' className='hint-content'>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_CONTENT_CATEGORY_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <div className='hint-container'>{defaultLangData.subItems[catIndex].catName}</div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const handleToggleFrozen = () => {
    const defaultIsFrozen = findTargetById(defaultLangData, catId).isFrozen
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, catId)
        currentUnit.isFrozen = !defaultIsFrozen
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, catId)
          currentUnit.isFrozen = !currentUnit.isFrozen
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const getIsFrozenSwitcher = () => {
    return (
      <label className='btn-frozen input-label' htmlFor={`${currentLanguage}_frozen_${catId}`}>
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            langData.subItems[catIndex].isFrozen
              ? t('CON_CONTENT_TOOLTIP_FROZEN')
              : t('CON_CONTENT_TOOLTIP_NOT_FROZEN')
          )}
          target={target.current}
        >
          <div className=''>
            {langData.subItems[catIndex].isFrozen ? (
              <IconLockClosed color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
            ) : (
              <IconLockOpened color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
            )}
            <input
              type='checkbox'
              id={`${currentLanguage}_frozen_${catId}`}
              name='isFrozen'
              value={langData.subItems[catIndex].isFrozen}
              onClick={
                isLocalsLoading
                  ? null
                  : (e) => {
                      handleToggleFrozen(e)
                    }
              }
            />
          </div>
        </OverlayTrigger>
      </label>
    )
  }

  const getColumnSwitcher = () => {
    return (
      <label className='btn-columns input-label' htmlFor={`${currentLanguage}_columns_${catId}`}>
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            langData.subItems[catIndex].isThreeColumnLayout
              ? t('CON_CONTENT_TOOLTIP_3_COLUMNS')
              : t('CON_CONTENT_TOOLTIP_2_COLUMNS')
          )}
          target={target.current}
        >
          <div className=''>
            {langData.subItems[catIndex].isThreeColumnLayout ? (
              <IconColumns3 color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
            ) : (
              <IconColumns2 color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
            )}
            <input
              type='checkbox'
              id={`${currentLanguage}_columns_${catId}`}
              name='isThreeColumnLayout'
              value={langData.subItems[catIndex].isThreeColumnLayout}
              onClick={
                isLocalsLoading
                  ? null
                  : (e) => {
                      handleToggleColumns(e)
                    }
              }
            />
          </div>
        </OverlayTrigger>
      </label>
    )
  }

  const getNameAndButton = () => (
    <div className='name-and-delete'>
      {getHint()}
      {!isDesktop && getDefaultLanguageButtons()}

      <div className='actions-block'>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('CON_CONTENT_CATEGORY_TEXT')}</InputGroup.Text>
          </InputGroup.Prepend>
          <UnitNameDebouncedInput
            incomingKind='cat'
            incomingName='catName'
            incomingValue={langData.subItems[catIndex].catName}
            incomingId={catId}
            incomingIndex={catIndex}
          />
        </InputGroup>
        {currentLanguage === defaultLanguage && getColumnSwitcher()}
        {isAdmin && currentLanguage === defaultLanguage && getIsFrozenSwitcher()}
        {currentLanguage === defaultLanguage && (isDesktop ? getDeleteButton() : dragDownBtn)}
        {currentLanguage !== defaultLanguage && getHideButton()}
      </div>
    </div>
  )

  const getHeader = () => (
    <Row
      aria-expanded={isOpen}
      className={`unit-header expandable${isOpen ? ' open' : ''}${
        currentLanguage !== defaultLanguage ? ' translated' : ''
      }`}
    >
      <div className='expand-btn' onClick={() => openizator(catId, showList, setShowList)} />
      {getNameAndButton()}
      {isDesktop && getDefaultLanguageButtons()}
    </Row>
  )

  const getContent = () => (
    <Collapse in={isOpen}>
      <Container className='unit-content' fluid>
        <SubcatDragWrapper cat={catIndex}>{getSubcatList()}</SubcatDragWrapper>

        {currentLanguage === defaultLanguage && (
          <Row className='control-box'>
            <AddButton onClick={handleCreateSubcategory} textKey='CON_CONTENT_SUBCATEGORY_TEXT' />
          </Row>
        )}
      </Container>
    </Collapse>
  )

  return langData ? (
    <Container
      className={`category-container ${langData.subItems[catIndex].isVisible ? '' : 'grayed'}`}
      as='section'
      id={catId}
      fluid
    >
      {getHeader()}
      {getContent()}
    </Container>
  ) : null
}

export default Category
