import React from 'react'
const IconListCheck = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill={color}
    >
      <path d='M20 6L3 6' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M10 11L3 11' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M10 16H3' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M14 13.5L16.1 16L20 11'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default IconListCheck
