import { getContentHeight } from './getContentHeight'
import { createItemContent } from './createItemContent'
import { PDF_HEIGHT } from './constants'
export const distributeContentAcrossPages = (data, currentMenu) => {
  const pages = []
  let currentPage = {
    data: [[], [], []],
    isThreeColumnLayout: data[0]?.isThreeColumnLayout || false,
    isFullyFilled: [false, false, false],
  }
  let currentPageHeight = [0, 0, 0]
  const maxPageHeight = PDF_HEIGHT

  data.forEach((category) => {
    const isThreeColumnLayout = category.isThreeColumnLayout || false
    const categoryHeader = category?.catName
      ? `<h1 class="ml-0 w-full font-lato text-black ${
          isThreeColumnLayout ? 'text-[15px] max-w-[220px]' : 'text-[17px] max-w-[345px]'
        } font-extrabold leading-normal mt-0 pb-2 border-b border-b-gray-300 mb-3">${
          category.catName
        }</h1>`
      : null
    const categoryHeaderHeight = getContentHeight(categoryHeader)
    let categoryAdded = false

    if (isThreeColumnLayout !== currentPage.isThreeColumnLayout) {
      if (
        currentPage.data[0].length > 0 ||
        currentPage.data[1].length > 0 ||
        currentPage.data[2].length > 0
      ) {
        pages.push(currentPage)
        currentPage = {
          data: [[], [], []],

          isThreeColumnLayout,
          isFullyFilled: [false, false, false],
        }
        currentPageHeight = [0, 0, 0]
      }
    }

    category.subItems.forEach((item) => {
      const subItemHeader = item?.subcatName
        ? `<h3 class="ml-0 w-full font-lato text-black ${
            isThreeColumnLayout ? 'text-[12px] max-w-[220px]' : 'text-[14px] max-w-[345px]'
          } leading-normal pb-1.5 border-b border-b-gray-300 mt-0 mb-2">${item.subcatName}</h3>`
        : null
      const subItemHeaderHeight = getContentHeight(subItemHeader)
      let subCategoryAdded = false

      item.subItems.forEach((subItem, index) => {
        const isLastItem = index === item.subItems.length - 1

        const subItemContentObj = createItemContent(
          subItem,
          isThreeColumnLayout,
          isLastItem,
          currentMenu,
          subItem.isListMode
        )

        const totalHeightColumn1 =
          currentPageHeight[0] +
          subItemContentObj.height +
          (!subCategoryAdded ? subItemHeaderHeight : 0) +
          (!categoryAdded ? categoryHeaderHeight : 0)
        const totalHeightColumn2 =
          currentPageHeight[1] +
          subItemContentObj.height +
          (!subCategoryAdded ? subItemHeaderHeight : 0) +
          (!categoryAdded ? categoryHeaderHeight : 0)
        const totalHeightColumn3 =
          currentPageHeight[2] +
          subItemContentObj.height +
          (!subCategoryAdded ? subItemHeaderHeight : 0) +
          (!categoryAdded ? categoryHeaderHeight : 0)

        if (isThreeColumnLayout) {
          if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
            if (!categoryAdded) {
              currentPage.data[0].push(categoryHeader)
              currentPageHeight[0] += categoryHeaderHeight
              categoryAdded = true
            }
            if (!subCategoryAdded) {
              currentPage.data[0].push(subItemHeader)
              currentPageHeight[0] += subItemHeaderHeight
              subCategoryAdded = true
            }
            currentPage.data[0].push(subItemContentObj.content)
            currentPageHeight[0] += subItemContentObj.height
            if (currentPageHeight[0] >= maxPageHeight) {
              currentPage.isFullyFilled[0] = true
            }
          } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
            if (!categoryAdded) {
              currentPage.data[1].push(categoryHeader)
              currentPageHeight[1] += categoryHeaderHeight
              categoryAdded = true
            }
            if (!subCategoryAdded) {
              currentPage.data[1].push(subItemHeader)
              currentPageHeight[1] += subItemHeaderHeight
              subCategoryAdded = true
            }
            currentPage.data[1].push(subItemContentObj.content)
            currentPageHeight[1] += subItemContentObj.height
            if (currentPageHeight[1] >= maxPageHeight) {
              currentPage.isFullyFilled[1] = true
            }
            currentPage.isFullyFilled[0] = true
          } else if (totalHeightColumn3 <= maxPageHeight && !currentPage.isFullyFilled[2]) {
            if (!categoryAdded) {
              currentPage.data[2].push(categoryHeader)
              currentPageHeight[2] += categoryHeaderHeight
              categoryAdded = true
            }
            if (!subCategoryAdded) {
              currentPage.data[2].push(subItemHeader)
              currentPageHeight[2] += subItemHeaderHeight
              subCategoryAdded = true
            }
            currentPage.data[2].push(subItemContentObj.content)
            currentPageHeight[2] += subItemContentObj.height
            if (currentPageHeight[2] >= maxPageHeight) {
              currentPage.isFullyFilled[2] = true
            }
            currentPage.isFullyFilled[1] = true
          } else {
            pages.push(currentPage)
            currentPage = {
              data: [[], [], []],

              isThreeColumnLayout,
              isFullyFilled: [false, false, false],
            }
            currentPageHeight = [0, 0, 0]

            currentPage.data[0].push(categoryHeader)
            currentPage.data[0].push(subItemHeader)
            currentPage.data[0].push(subItemContentObj.content)
            currentPageHeight[0] +=
              categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
            categoryAdded = true
            subCategoryAdded = true
          }
        } else {
          if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
            if (!categoryAdded) {
              currentPage.data[0].push(categoryHeader)
              currentPageHeight[0] += categoryHeaderHeight
              categoryAdded = true
            }
            if (!subCategoryAdded) {
              currentPage.data[0].push(subItemHeader)
              currentPageHeight[0] += subItemHeaderHeight
              subCategoryAdded = true
            }
            currentPage.data[0].push(subItemContentObj.content)
            currentPageHeight[0] += subItemContentObj.height
            if (currentPageHeight[0] >= maxPageHeight) {
              currentPage.isFullyFilled[0] = true
            }
          } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
            if (!categoryAdded) {
              currentPage.data[1].push(categoryHeader)
              currentPageHeight[1] += categoryHeaderHeight
              categoryAdded = true
            }
            if (!subCategoryAdded) {
              currentPage.data[1].push(subItemHeader)
              currentPageHeight[1] += subItemHeaderHeight
              subCategoryAdded = true
            }
            currentPage.data[1].push(subItemContentObj.content)
            currentPageHeight[1] += subItemContentObj.height
            if (currentPageHeight[1] >= maxPageHeight) {
              currentPage.isFullyFilled[1] = true
            }
            currentPage.isFullyFilled[0] = true
          } else {
            pages.push(currentPage)
            currentPage = {
              data: [[], [], []],

              isThreeColumnLayout,
              isFullyFilled: [false, false, false],
            }
            currentPageHeight = [0, 0, 0]

            currentPage.data[0].push(categoryHeader)
            currentPage.data[0].push(subItemHeader)
            currentPage.data[0].push(subItemContentObj.content)
            currentPageHeight[0] +=
              categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
            categoryAdded = true
            subCategoryAdded = true
          }
        }
      })
    })
  })

  if (
    currentPage.data[0].length > 0 ||
    currentPage.data[1].length > 0 ||
    currentPage.data[2].length > 0
  ) {
    pages.push(currentPage)
  }

  return pages
}
