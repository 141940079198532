import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { ntfWarn } from '../helpers/notifications'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import { validateUnitName } from '../validation/validate'

const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const NameGroupDebouncedInput = ({
  incomingName,
  incomingValue,
  incomingId,
  catIndex,
  subcatIndex,
  itemIndex,
  variantGroup,
}) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  // const [inputValue, setInputValue] = useState(incomingValue)
  const [nameValue, setNameValue] = useState(incomingValue)

  useEffect(() => {
    const targetItem = menuLocals.find((langObj) => langObj.lang === currentLanguage)?.subItems?.[
      catIndex
    ]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]?.[`subVariant${variantGroup}`]

    if (targetItem?.name !== undefined && targetItem?.name !== nameValue) {
      // console.log(`[useEffect] Updating name input: ${targetItem?.name}`)
      setNameValue(targetItem?.name)
    }
  }, [incomingValue, menuLocals, currentLanguage])

  const handleNameChange = (name, value, incomingId) => {
    // console.log(`[handleNameChange] Updating: ${name} = ${value}, ID: ${incomingId}`)

    const _menuLocals = menuLocals.map((menu) => {
      if (menu.lang === currentLanguage) {
        const item =
          menu.subItems?.[catIndex]?.subItems?.[subcatIndex]?.subItems?.[itemIndex]?.[
            `subVariant${variantGroup}`
          ]

        if (!item) {
          // console.error(`[handleNameChange] Item with ID ${incomingId} not found in menuLocals`)
          return menu
        }

        item.name = value
      }
      return menu
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleNameChange = useDebouncedCallback(
    handleNameChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  return (
    <input
      className='form-control'
      name={incomingName}
      id={`subvariant-${catIndex}-${subcatIndex}-${itemIndex}-${variantGroup}-name`}
      value={nameValue}
      placeholder={placeholders.subVariantGroupName}
      disabled={isLocalsLoading}
      onChange={(e) => {
        const value = e.target.value

        // console.log(`[onChange] User typed: ${incomingName} = ${value}, ID: ${incomingId}`)

        // if (value.length > 250) {
        //   ntfWarn({
        //     title: t('MESSAGE_ERROR_TITLE'),
        //     message: 'Name is too long (max 250 characters)',
        //   })
        //   return
        // }

        // setInputValue(value)
        // debouncedHandleNameChange(incomingName, value, incomingId)

        const error = validateUnitName(value).error
        if (!error) {
          setNameValue(value)
          debouncedHandleNameChange(incomingName, value, incomingId)
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_250_CHARACTERS_MAX') })
        }
      }}
    />
  )
}

export default NameGroupDebouncedInput
