import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Card,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Container,
  Collapse,
  Form,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { findTargetById, renderTooltip } from '../helpers'
import { useIsDesktop, useDragDown, useDragUp } from '../helpers/hooks'
import icons from '../img/icons/index'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
  RootSetterContext,
} from '../context/userContext'
import SubVariantCardDebouncedInput from './SubVariantCardDebouncedInput'

const { IconRemoveItem, IconEyeVisible, IconEyeHidden, IconDragOld } = icons

const SubVariant = ({
  name,
  catIndex,
  subcatIndex,
  itemIndex,
  variantIndex,
  variantGroup,
  itemId,
}) => {
  const { t } = useTranslation()
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { defaultLanguage, currentLanguage } = useContext(ConstructorGetterContext)
  const [defaultLangData, setDefaultLangData] = useState(null)

  const [langData, setLangData] = useState(null)
  const [isOpen, setIsOpen] = useState(true)

  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      const _langData = menuLocals.find((langObj) => langObj.lang === currentLanguage)
      setLangData(_langData)
    }
  }, [menuLocals, currentLanguage])

  useEffect(() => {
    if (menuLocals && defaultLanguage) {
      const _defaultLangData = menuLocals.find((langObj) => langObj.lang === defaultLanguage)
      setDefaultLangData(_defaultLangData)
    }
  }, [menuLocals, defaultLanguage])

  const target = useRef(null)

  const handleToggleVisibilitySubVariant = () => {
    const _menuLocals = menuLocals.map((local) => {
      const subVariant =
        local.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
          `subVariant${variantGroup}`
        ]?.values?.[variantIndex]

      if (subVariant) {
        subVariant.isVisible = !subVariant.isVisible
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteSubVariant = () => {
    menuLocals.forEach((local) => {
      local.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex][
        `subVariant${variantGroup}`
      ].values = langData.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex][
        `subVariant${variantGroup}`
      ].values.filter((_, index) => index !== variantIndex)
    })

    setMenuLocals(menuLocals)
    setIsThereUnsavedChanges(true)
    return true
  }

  const getHideButton = () => {
    const isVisible =
      menuLocals[0].subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
        `subVariant${variantGroup}`
      ]?.values?.[variantIndex]?.isVisible ?? true

    const buttonItself = (
      <label
        className='btn-hide input-label'
        htmlFor={`${currentLanguage}_${itemIndex}_${variantGroup}_${variantIndex}`}
      >
        {isVisible ? (
          <IconEyeVisible color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        ) : (
          <IconEyeHidden color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        )}
        <input
          type='checkbox'
          id={`${currentLanguage}_${itemIndex}_${variantGroup}_${variantIndex}`}
          name='isVisible'
          checked={isVisible}
          onChange={() => {
            if (!isLocalsLoading) {
              handleToggleVisibilitySubVariant(itemIndex, variantGroup, variantIndex)
            }
          }}
        />
      </label>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_HIDE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDeleteButton = () => {
    const buttonItself = (
      <div
        className='btn-delete'
        onClick={
          isLocalsLoading
            ? null
            : () => {
                setShowRemoveModal({
                  show: true,
                  name: langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                    `subVariant${variantGroup}`
                  ]?.values?.[variantIndex]?.subVariantName,
                  kind: t('CON_MODAL_DELETE_SUBVARIANT'),
                  cbName: handleDeleteSubVariant,

                  cbArgs: [],
                })
              }
        }
      >
        <IconRemoveItem color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DELETE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDefaultLanguageButtons = () =>
    currentLanguage === defaultLanguage && (
      <Col className='default-language-buttons'>
        {isDesktop ? (
          <>
            <div className='toggleHide'>{getHideButton()}</div>
          </>
        ) : (
          <>
            {getDeleteButton()}
            {getHideButton()}
          </>
        )}
      </Col>
    )

  const getSubVariantHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
        `subVariant${variantGroup}`
      ]?.values?.[variantIndex]?.subVariantName

    return (
      <>
        {showHint ? (
          <div className='hint-row'>
            <InputGroup size='sm' className='hint-content'>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_CONTENT_SUBVARIANT_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <div className='hint-container'>
                {defaultLangData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                  `subVariant${variantGroup}`
                ]?.values?.[variantIndex]?.subVariantName || ''}
              </div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const getNameAndButton = () => (
    <div className='name-and-delete'>
      {getSubVariantHint()}
      {!isDesktop && getDefaultLanguageButtons()}

      <div className='actions-block'>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('CON_CONTENT_SUBVARIANT_TEXT')}</InputGroup.Text>
          </InputGroup.Prepend>
          <SubVariantCardDebouncedInput
            incomingName='subVariantName'
            incomingValue={name}
            incomingId={itemId}
            catIndex={catIndex}
            subcatIndex={subcatIndex}
            itemIndex={itemIndex}
            variantIndex={variantIndex}
            variantGroup={variantGroup}
          />
        </InputGroup>
        {currentLanguage === defaultLanguage &&
          (isDesktop ? getDeleteButton() : <span style={{ marginRight: '10px' }} />)}
        {currentLanguage !== defaultLanguage && getHideButton()}
      </div>
    </div>
  )

  const getHeader = () => (
    <Row
      aria-expanded={isOpen}
      className={`unit-header-subvariant
         expandable${isOpen ? ' open' : ''}${
        currentLanguage !== defaultLanguage ? ' translated' : ''
      }`}
    >
      {getNameAndButton()}
      {isDesktop && getDefaultLanguageButtons()}
    </Row>
  )

  const getSubVariantSkuBlock = () => (
    <Col className='sku-block'>
      <InputGroup size='sm'>
        <InputGroup.Prepend>
          <InputGroup.Text>{t('CON_CONTENT_ITEM_SKU')}</InputGroup.Text>
        </InputGroup.Prepend>

        {currentLanguage === defaultLanguage ? (
          <SubVariantCardDebouncedInput
            incomingName='sku'
            incomingValue={
              langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                `subVariant${variantGroup}`
              ]?.values[variantIndex]?.sku
            }
            incomingId={itemId}
            catIndex={catIndex}
            subcatIndex={subcatIndex}
            itemIndex={itemIndex}
            variantIndex={variantIndex}
            variantGroup={variantGroup}
          />
        ) : (
          <Form.Control
            size='sm'
            type='text'
            value={
              langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                `subVariant${variantGroup}`
              ]?.values[variantIndex]?.sku
            }
            disabled
          />
        )}
      </InputGroup>
    </Col>
  )

  const getSubVariantPriceBlock = () => (
    <Col className='price-block'>
      <InputGroup size='sm'>
        <InputGroup.Prepend>
          <InputGroup.Text>{t('CON_CONTENT_ITEM_PRICE')}</InputGroup.Text>
        </InputGroup.Prepend>

        {currentLanguage === defaultLanguage ? (
          <SubVariantCardDebouncedInput
            incomingName='price'
            incomingValue={
              langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                `subVariant${variantGroup}`
              ]?.values[variantIndex]?.price
            }
            incomingId={itemId}
            catIndex={catIndex}
            subcatIndex={subcatIndex}
            itemIndex={itemIndex}
            variantIndex={variantIndex}
            variantGroup={variantGroup}
          />
        ) : (
          <Form.Control
            size='sm'
            type='text'
            value={
              langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
                `subVariant${variantGroup}`
              ]?.values[variantIndex]?.price
            }
            disabled
          />
        )}
        <InputGroup.Append>
          <InputGroup.Text>{menuLocals[0].measureUnits.currency}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  )

  const getContent = () => (
    <Collapse in={isOpen}>
      <Container className='unit-content' fluid>
        <Row className='measurements'>
          {getSubVariantSkuBlock()}
          {getSubVariantPriceBlock()}
        </Row>
      </Container>
    </Collapse>
  )

  return langData ? (
    <Container
      className={`item-container-variant ${
        menuLocals[0].subItems[catIndex]?.subItems[subcatIndex]?.subItems[itemIndex]?.[
          `subVariant${variantGroup}`
        ]?.values?.[variantIndex]?.isVisible
          ? ''
          : 'grayed'
      }`}
      as='ul'
      id={variantIndex}
      fluid
    >
      {getHeader()}
      {getContent()}
    </Container>
  ) : null
}

export default SubVariant
