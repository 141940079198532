require('dotenv').config()
const Joi = require('joi')

const measureUnitSchema = Joi.string().pattern(/^\D+$/).max(3).allow('')
const measureLargeUnitSchema = Joi.string().pattern(/^\D+$/).max(6).allow('')

const unitNameSchema = Joi.string().max(250).allow('')
const itemDescriptionSchema = Joi.string().max(300).allow('')
const itemMeasureSchema = Joi.string().max(10).allow('')

const emailSchema = Joi.string()
  .email({
    tlds: { allow: false },
  })
  .optional()

const userAuthIdSchema = Joi.string()
  .min(16)
  .max(64)
  .pattern(/^[a-zA-Z0-9-]+|[0-9A-Fa-f]+$/)
  .required()

const userIdSchema = Joi.string().guid().required()
const menuIdSchema = Joi.string().guid().required()

const variantSchema = Joi.object({
  id: Joi.string().required(),
  isVisible: Joi.boolean().required(),
  subVariantName: Joi.string().min(1).max(250),
  // subVariantName: Joi.string().max(1000).allow(''),
  price: Joi.string().max(6).allow(''),
  sku: Joi.string().max(50).allow(''),
})

const itemSchema = Joi.object({
  id: Joi.string(),
  isVisible: Joi.boolean().required(),
  isListMode: Joi.boolean().default(false),
  itemName: Joi.string().max(1000).allow(''),
  description: Joi.string().max(10000).allow(''),
  weight: Joi.string().max(6).allow(''),
  measure: Joi.string().pattern(/^[m]|[v]|[t]|[q]|[d]|[l]$/),
  price: Joi.string().max(6).allow(''),
  sku: Joi.string().max(50).allow(''),
  handle: Joi.string().max(1000).allow(''),
  image: Joi.alternatives().try(Joi.string().dataUri(), Joi.string()).allow(null, ''),
  subVariant1: Joi.object({
    name: Joi.string().max(1000).allow(''),
    values: Joi.array().items(variantSchema),
  }).optional(),
  subVariant2: Joi.object({
    name: Joi.string().max(1000).allow(''),
    values: Joi.array().items(variantSchema),
  }).optional(),
  subVariant3: Joi.object({
    name: Joi.string().max(1000).allow(''),
    values: Joi.array().items(variantSchema),
  }).optional(),
})

const subcategorySchema = Joi.object({
  id: Joi.string(),
  isVisible: Joi.boolean().required(),
  subcatName: Joi.string().max(250).allow(''),
  subItems: Joi.array().items(itemSchema),
})

const categorySchema = Joi.object({
  id: menuIdSchema,
  isVisible: Joi.boolean().required(),
  catName: Joi.string().max(250).allow(''),
  isThreeColumnLayout: Joi.boolean().default(false),
  isFrozen: Joi.boolean().default(false),
  subItems: Joi.array().items(subcategorySchema),
})

const restNameSchema = Joi.string().max(24).allow('')

const measureUnitsSchema = Joi.object({
  mass: measureUnitSchema,
  volume: measureUnitSchema,
  time: measureLargeUnitSchema,
  quantity: measureLargeUnitSchema,
  currency: measureUnitSchema,
  diameter: measureLargeUnitSchema,
  length: measureUnitSchema,
})

const menuTranslationSchema = Joi.object({
  lang: Joi.string()
    .pattern(/^[a-z]{2}$/)
    .required(),
  isPublished: Joi.boolean(),
  restName: Joi.string().max(100).allow(''),
  menuName: Joi.string().allow(''),
  measureUnits: measureUnitsSchema,
  subItems: Joi.array().items(categorySchema),
})

const colorItemSchema = Joi.string()
  .pattern(/^[#][0-9,a-f]{6}$/)
  .required()

const aliasWordSchema = Joi.string()
  .min(+process.env.REACT_APP_MIN_ALIAS_LENGTH)
  .max(+process.env.REACT_APP_MAX_ALIAS_LENGTH)
  .pattern(/^[\w-]*$/)
  .required()

const aliasSchema = Joi.object({
  word: aliasWordSchema,
})

const importedTranslationSchema = Joi.object({
  columnName: Joi.string().required(),
  translations: Joi.object().pattern(Joi.string(), Joi.string().allow('')).required(),
})

const menuItemSchema = Joi.object({
  id: menuIdSchema,
  url: Joi.string().uri().allow('#'),
  alias: Joi.string()
    .min(+process.env.REACT_APP_MIN_ALIAS_LENGTH)
    .max(+process.env.REACT_APP_MAX_ALIAS_LENGTH)
    .pattern(/^[\w-]*$/)
    .required(),
  isDeleted: Joi.boolean(),
  events: Joi.object({
    createdAt: Joi.date().iso().allow(null),
    editedAt: Joi.date().iso().allow(null),
    publishedAt: Joi.date().iso().allow(null),
    buildedAt: Joi.date().iso().allow(null),
    failedAt: Joi.date().iso().allow(null),
  }),
  splashBgColor: colorItemSchema,
  mainBgColor: colorItemSchema,
  splashTextColor: colorItemSchema,
  mainTextColor: colorItemSchema,
  defaultLanguage: Joi.string()
    .pattern(/^[a-z]{2}$/)
    .required(),
  socials: Joi.object({
    facebook: Joi.string().allow(''),
    instagram: Joi.string().allow(''),
    tripadvisor: Joi.string().allow(''),
    whatsapp: Joi.string().allow(''),
    telegram: Joi.string().allow(''),
    tikTok: Joi.string().allow(''),
    linkTree: Joi.string().allow(''),
    googleMaps: Joi.string().allow(''),
    openTable: Joi.string().allow(''),
    website: Joi.string().allow(''),
    email: Joi.string().allow(''),
    customLink: Joi.string().allow(''),
  }),
  categoriesList: Joi.string().min(2).max(6).required(),
  // logo: Joi.string().dataUri().allow(''),
  // logo: Joi.string().guid(),
  logo: Joi.alternatives().try(Joi.string().dataUri(), Joi.string()).allow(null, ''),
  templateName: Joi.string().allow('').default('default'),
  wifi: Joi.object({
    ssid: Joi.string().allow(''),
    password: Joi.string().allow(''),
  }).allow(null, ''),
  importedTranslations: Joi.array().items(importedTranslationSchema).allow(null),
  content: Joi.array().items(menuTranslationSchema).has(menuTranslationSchema),
}).required()

const userSchema = Joi.object({
  id: userIdSchema,
  authId: userAuthIdSchema,
  userName: Joi.string().allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  menuList: Joi.array().items(menuItemSchema).allow(''),
}).required()

const createUserSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(''),
  userAuthId: userAuthIdSchema,
  userName: Joi.string().allow(''),
})

const getAuthUserSchema = Joi.object({
  userAuthId: userAuthIdSchema,
})

const getUserSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  withDeleted: Joi.boolean().optional(),
})

const getPdfDataSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  menuId: menuIdSchema,
  lang: Joi.string(),
})

const createMenuSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  menuData: menuItemSchema,
})

const updateMenuSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  menuData: menuItemSchema,
})

const swapAliasesSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  alias1: aliasWordSchema,
  alias2: aliasWordSchema,
})

const publishMenuSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  menuId: menuIdSchema,
  alias: Joi.string()
    .min(+process.env.REACT_APP_MIN_ALIAS_LENGTH)
    .max(+process.env.REACT_APP_MAX_ALIAS_LENGTH)
    .pattern(/^[\w-]*$/)
    .required(),
  templateName: Joi.string().allow('').default('default'),
})

const importCsvMenuSchema = Joi.array().items(
  Joi.object({
    Handle: Joi.string().allow(''),
    SKU: Joi.string().allow(''),
    Name: Joi.string().allow(''),
    Category: Joi.string().allow(''),
    Description: Joi.string().allow(''),
    'Sold by weight': Joi.string().allow(''),
    'Option 1 name': Joi.string().allow(''),
    'Option 1 value': Joi.string().allow(''),
    'Option 2 name': Joi.string().allow(''),
    'Option 2 value': Joi.string().allow(''),
    'Option 3 name': Joi.string().allow(''),
    'Option 3 value': Joi.string().allow(''),
    Cost: Joi.string().allow(''),
    Barcode: Joi.string().allow(''),
    'SKU of included item': Joi.string().allow(''),
    'Quantity of included item': Joi.string().allow(''),
    'Track stock': Joi.string().allow(''),
    'Available for sale [Moonhut Beach Restaurant]': Joi.string().allow(''),
    'Price [Moonhut Beach Restaurant]': Joi.string().allow(''),
    'In stock [Moonhut Beach Restaurant]': Joi.string().allow(''),
    'Low stock [Moonhut Beach Restaurant]': Joi.string().allow(''),
    'Tax - "VAT" (7%)': Joi.string().allow(''),
  }).unknown(true)
)

const getMenuSchema = Joi.object({
  userAuthId: userAuthIdSchema,
  menuId: menuIdSchema,
})

const validateMenuSchema = menuItemSchema

const validateAliasSchema = aliasSchema

const validateSummarySchema = Joi.object({
  userAuthId: userAuthIdSchema,
})

module.exports = {
  colorItemSchema,
  measureUnitSchema,
  measureLargeUnitSchema,
  itemMeasureSchema,
  itemDescriptionSchema,
  unitNameSchema,
  userSchema,
  emailSchema,
  userIdSchema,
  menuIdSchema,
  itemSchema,
  subcategorySchema,
  categorySchema,
  restNameSchema,
  measureUnitsSchema,
  menuTranslationSchema,
  createUserSchema,
  createMenuSchema,
  updateMenuSchema,
  publishMenuSchema,
  getMenuSchema,
  getAuthUserSchema,
  getUserSchema,
  getPdfDataSchema,
  validateMenuSchema,
  validateAliasSchema,
  validateSummarySchema,
  importCsvMenuSchema,
  swapAliasesSchema,
}
