import QRCodeStyling from 'qr-code-styling'
import qr from 'qrcode'
import CameraIcon from '../img/camera-icon.svg'
import { getWifiQRString, blobToDataURL } from '../helpers'
import { PDF_HEIGHT, PDF_HEIGHT_A5 } from '../helpersPDF/constants'

const SOCIALS_ICONS = [
  { name: 'Website', icon: 'website.svg' },
  { name: 'Facebook', icon: 'facebook.svg' },
  { name: 'Instagram', icon: 'instagram.svg' },
]

export const createPreviewPageContent = async (currentMenu, templateName, type = 'A4') => {
  const publishedDate = new Date(
    currentMenu.data.events?.publishedAt ||
      currentMenu.data.events?.editedAt ||
      currentMenu.data.events?.createdAt
  )
  const day = String(publishedDate.getDate()).padStart(2, '0')
  const month = String(publishedDate.getMonth() + 1).padStart(2, '0')
  const year = publishedDate.getFullYear()
  const formattedDate = `${day}.${month}.${year}`

  const qrWifiString = await getWifiQRString(
    currentMenu?.data?.wifi?.ssid,
    currentMenu?.data?.wifi?.password
  )
  const qrWifi = await qr.toDataURL(qrWifiString, { type: 'image/png' })

  const qrMenuObject = new QRCodeStyling({
    width: 300,
    height: 300,
    type: 'canvas',
    data: `https://${currentMenu?.data?.alias}.gimme.menu`,
    qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
    imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3, margin: 8 },
    dotsOptions: {
      type: 'extra-rounded',
      color: '#000',
      gradient: {
        type: 'linear',
        colorStops: [
          { offset: 0, color: '#000000' },
          { offset: 1, color: '#666666' },
        ],
      },
    },
    cornersSquareOptions: {
      type: 'rounded',
      gradient: {
        type: 'linear',
        colorStops: [
          { offset: 0, color: '#000000' },
          { offset: 1, color: '#808080' },
        ],
      },
    },
    cornersDotOptions: {
      type: 'extra-rounded',
      color: '#000',
      gradient: {
        type: 'linear',
        colorStops: [
          { offset: 0, color: '#000000' },
          { offset: 1, color: '#707070' },
        ],
      },
    },
    image: templateName === 'premium' ? CameraIcon : '',
  })

  const qrMenu = await blobToDataURL(await qrMenuObject.getRawData('png'))

  const qrReview = currentMenu?.data?.socials?.customLink
    ? await qr.toDataURL(currentMenu?.data?.socials?.customLink, { type: 'image/png' })
    : ''

  const generateQR = async (content) => {
    return await qr.toDataURL(content, { type: 'image/png' })
  }

  const socialsHTML = await Promise.all(
    SOCIALS_ICONS.map(async (social, index) => {
      const socialName = social?.name
      const socialValue = currentMenu?.data?.socials?.[socialName.toLowerCase()]
      if (!socialName || !socialValue?.length) {
        return ''
      }

      const generatedQr = await generateQR(socialValue)
      return `
        <div key="${index}" class="flex flex-col items-center gap-2">
          <div class="flex items-center justify-center gap-1 relative z-10">
            <img class="mt-1 h-4 w-auto max-w-4" src="./socials/${
              social?.icon
            }" alt="${socialName}" />
            <p class="mb-2 font-lato text-[11px] text-black leading-normal">${socialName}</p>
          </div>
          ${
            generatedQr
              ? `<img style="width: ${type === 'A5' ? '80px' : '100px'}; height: ${
                  type === 'A5' ? '80px' : '100px'
                };" src="${generatedQr}" alt="qr" />`
              : ''
          }
        </div>
      `
    })
  )

  const pageHeight = type === 'A5' ? PDF_HEIGHT_A5 : PDF_HEIGHT
  const logoMaxWidth = type === 'A5' ? '150px' : '300px'
  const qrCodeSize = type === 'A5' ? '80px' : '140px'
  const qrCodeSizeBig = type === 'A5' ? '150px' : '240px'

  return `
    <div style="height: ${pageHeight}px;" class="break-after-page flex flex-col justify-between">
      ${
        currentMenu?.data?.logo
          ? `<img class="mx-auto mt-16" style="max-width: ${logoMaxWidth}; width: 100%;" src="https://res.cloudinary.com/gimmemenu/image/upload/f_png,w_500,c_pad/${currentMenu?.data?.logo}" alt="${currentMenu.data.content[0]?.restName}" />`
          : '&nbsp;'
      }
      <div>
        <h1 class="text-center font-lato text-black text-[20px] font-extrabold mb-4">
          ${type === 'A5' ? 'Drinks Menu' : 'Menu'}
        </h1>
        <div class="flex justify-between gap-8 max-w-2xl w-full mx-auto items-end">
          <div style="width: ${qrCodeSize};" class="flex flex-col items-center">
            ${
              currentMenu?.data?.wifi?.ssid
                ? `
                  <div class="flex items-center justify-center gap-1 relative z-10">
                    <img class="mt-1 h-6 w-6" src="./preview/wifi.svg" alt="wifi" />
                    <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan for Wi-Fi</p>
                  </div>
                  <img style="width: ${qrCodeSize}; height: ${qrCodeSize};" src="${qrWifi}" alt="wifi" />
                  <div>
                    <p class="text-[10px] text-black font-lato text-center leading-normal">
                      Name: <span class="font-bold">${currentMenu?.data?.wifi?.ssid}</span>
                    </p>
                    ${
                      currentMenu?.data?.wifi?.password
                        ? `<p class="text-[10px] text-black font-lato text-center leading-normal">
                            Pass: <span class="font-bold">${currentMenu?.data?.wifi?.password}</span>
                          </p>`
                        : ''
                    }
                  </div>
                `
                : ''
            }
          </div>
          <div class="flex flex-col items-center">
            <div class="flex items-center justify-center gap-1 relative z-10">
              <img class="mt-1 h-6 w-6" src="./preview/menu.svg" alt="menu" />
              <p class="mb-3 font-lato text-[13px] text-black leading-normal">${
                templateName === 'premium' ? 'Scan for Menu with Photos' : 'Scan for Menu'
              }</p>
            </div>
            <img style="width: ${qrCodeSizeBig}; height: ${qrCodeSizeBig};" src="${qrMenu}" alt="menu" />
          </div>
          <div style="width: ${qrCodeSize};" class="flex flex-col items-center">
            ${
              currentMenu?.data?.socials?.customLink?.length
                ? `
                  <div class="flex flex-col items-center">
                    <div class="flex items-center justify-center gap-1 relative z-10">
                      <img class="mt-1 h-6 w-6" src="./preview/reviews.svg" alt="reviews" />
                      <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan to Review</p>
                    </div>
                    <img style="width: ${qrCodeSize}; height: ${qrCodeSize};" src="${qrReview}" alt="reviews" />
                    <p class="text-[10px] text-black font-lato text-center leading-normal">
                      We will appreciate<br />your review :)
                    </p>
                  </div>
                `
                : ''
            }
          </div>
        </div>
        <div>
          <p class="text-center font-lato text-[9px] leading-normal text-black pb-3 border-b border-b-gray-600">
            Menu updated on ${formattedDate}
          </p>
          <div class="mt-10 flex justify-center gap-14">
            ${socialsHTML.join('')}
          </div>
        </div>
      </div>
    </div>
  `
}

// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
//   dotsOptions: { type: 'dots', color: '#000' },
//   image: templateName === 'premium' ? CameraIcon : '',
//   cornersSquareOptions: { type: 'square' },
//   cornersDotOptions: { type: 'square' },
// })

// MARGIN ICON
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: {
//     crossOrigin: 'anonymous',
//     imageSize: 0.3,
//     margin: 8,
//   },
//   dotsOptions: { type: 'dots', color: '#000' },

//   image: templateName === 'premium' ? CameraIcon : '',
//   cornersSquareOptions: { type: 'square' },
//   cornersDotOptions: { type: 'square' },
// })

// ITALIC
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3, margin: 8 },
//   dotsOptions: {
//     type: 'classy-rounded',

//     color: '#000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#666666' },
//       ],
//     },
//   },
//   cornersSquareOptions: {
//     type: 'classy-rounded',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#808080' },
//       ],
//     },
//   },
//   cornersDotOptions: {
//     type: 'classy-rounded',

//     color: '#000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#707070' },
//       ],
//     },
//   },
//   image: templateName === 'premium' ? CameraIcon : '',
// })

// LIKE WORMS + GRADIENT
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3, margin: 8 },
//   dotsOptions: {
//     type: 'extra-rounded',

//     color: '#000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#666666' },
//       ],
//     },
//   },
//   cornersSquareOptions: {
//     type: 'rounded',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#808080' },
//       ],
//     },
//   },
//   cornersDotOptions: {
//     // type: 'classy',
//     // type: 'rounded',
//     type: 'extra-rounded',

//     color: '#000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#707070' },
//       ],
//     },
//   },
//   image: templateName === 'premium' ? CameraIcon : '',
// })

// LIKE WORMS
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3, margin: 8 },
//   dotsOptions: {
//     type: 'extra-rounded',
//     color: '#000',
//     gradient: {
//       type: 'linear',
//       rotation: 0,
//       colorStops: [
//         { offset: 0, color: '#000' },
//         { offset: 1, color: '#444' },
//       ],
//     },
//   },

//   image: templateName === 'premium' ? CameraIcon : '',
// })

// ТЕМНЫЙ 2Д
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
//   imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
//   dotsOptions: { type: 'square', color: '#000' },
//   cornersSquareOptions: { type: 'square', color: '#000' },
//   cornersDotOptions: { type: 'square', color: '#000' },
//   backgroundOptions: { color: 'rgba(255, 255, 255, 0)' },
// })

// qrMenuObject.append(document.getElementById('qr-container'))

// const applyCanvasShadow = async () => {
//   const canvas = await qrMenuObject._getElement('png')
//   if (canvas && canvas instanceof HTMLCanvasElement) {
//     const context = canvas.getContext('2d')
//     if (context) {
//       context.save()
//       context.shadowColor = 'rgba(0, 0, 0, 0.5)'
//       context.shadowBlur = 5
//       context.shadowOffsetX = 10
//       context.shadowOffsetY = 10
//       context.drawImage(canvas, 0, 0)
//       context.restore()
//     }
//   }
// }

// applyCanvasShadow()

// GRADIENT
// const qrMenuObject = new QRCodeStyling({
//   width: 300,
//   height: 300,
//   type: 'canvas',
//   data: `https://${currentMenu?.data?.alias}.gimme.menu`,
//   qrOptions: {
//     typeNumber: 0,
//     errorCorrectionLevel: 'Q',
//   },
//   dotsOptions: {
//     type: 'rounded',
//     color: '#000000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#666666' },
//       ],
//     },
//   },
//   cornersSquareOptions: {
//     type: 'extra-rounded',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#808080' },
//       ],
//     },
//   },
//   cornersDotOptions: {
//     type: 'square',
//     color: '#000000',
//     gradient: {
//       type: 'linear',
//       colorStops: [
//         { offset: 0, color: '#000000' },
//         { offset: 1, color: '#707070' },
//       ],
//     },
//   },
//   imageOptions: {
//     crossOrigin: 'anonymous',
//     imageSize: 0.2,
//     hideBackgroundDots: true,
//   },
//   backgroundOptions: {
//     color: '#ffffff',
//   },
// })
